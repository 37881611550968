import { IAuthToken, IConnector, IInfoContent, IInstallation, ISession, IStation, IUser, ICar, IVersionUpdate, State, IRfid, CarFilters, PlugType } from 'common_library';
import { Inject, Injectable, Signal, computed, effect } from "@angular/core";
import { APP_MAIN_RX_STATE } from 'src/app/app.module';
import { AppMainState, ButtonState, IUserVisibilityGroups } from 'src/app/types/state/app-main-state.interface';
import { RxState } from '@rx-angular/state';
import { CommonService } from '../utils/common.service';
import { CmdButton } from 'src/app/types/buttons/button-state.interface';
import { Point } from 'geojson';

@Injectable({
  providedIn: 'root'
})
export class MainStateService {
  public $selectedConnectorHasMineSession: Signal<boolean> = computed(() => {
    return !!this.$selectedConnectorMineSession();
  });

  public $selectedConnectorMineSession: Signal<ISession> = computed(() => {
    return this.$sessions()?.find(s => s.connectorId === this.$selectedConnector()?.id);
  });

  public $connectorCommandInProgress: Signal<boolean> = computed(() => {
    const currentConnectorId = this.$selectedConnector()?.id;
    const connectorButtonStateMap = this.$buttonState()?.get(currentConnectorId);
    // se non trovo il connettore nella mappa, resituisco false, cioè posso eseguire liberamente operazioni
    if (!connectorButtonStateMap) {
      console.log("$connectorCommandInProgress: no connettore su mappa -> false")
      return false;
    }

    // se trovo il connettore nella mappa, devo vedere se sono ancora nel range temporale valido: se lo sono, controllo che nel fratempo non sia arrivata 
    // una sessione con il comando che avevo lanciato
    const sessions = this.$sessions();
    const sessionForConnector = sessions.find(c => c.connectorId == currentConnectorId);
    if (connectorButtonStateMap.exipresAt > new Date()) {
      if (sessionForConnector) {
        const sessionState = sessionForConnector.state;
        if (
          (connectorButtonStateMap.command === CmdButton.Reserve && sessionState === State.RESERVED)
          ||
          (connectorButtonStateMap.command === CmdButton.StartCharge && sessionState !== State.RESERVED)
        ) {
          console.log("$connectorCommandInProgress: comando elaborato -> false")
          return false;
        }
      }
      // nel caso in cui il range sia ancora valido ma non ho una sessione conseguenza del comando inviato, rispondo true
      console.log("$connectorCommandInProgress: tempo non scaduto -> true")
      return true;
    }
    // se invece il range temporale è scaduto, allora restituisco false, cioè consento nuovamente operazioni
    else {
      console.log("$connectorCommandInProgress: comando scaduto -> false")
      return false;
    }
  });

  public $selectedUserCar: Signal<ICar> = computed(() => {
    const cars = this.$userCars();
    return cars.find((c) => c.isSelected) ?? cars[0];
  })

  //#region signals
  public $sessions = this.appMainState.signal('sessions');
  public $user = this.appMainState.signal('user');
  public $userCars = this.appMainState.signal('userCars');
  public $userRfids = this.appMainState.signal('userRfids');
  public $authTokenUser = this.appMainState.signal('authTokenUser');
  public $selectedCluster = this.appMainState.signal('selectedCluster');
  public $selectedInstallation = this.appMainState.signal('selectedInstallation');
  public $selectedStation = this.appMainState.signal('selectedStation');
  public $selectedConnector = this.appMainState.signal('selectedConnector');
  public $selectedUserCarForSession = this.appMainState.signal('selectedUserCarForSession');
  public $lastSelectedConnectorId = this.appMainState.signal('lastSelectedConnectorId');
  public $buttonState = this.appMainState.signal('buttonState');
  public $version = this.appMainState.signal('version');
  public $native = this.appMainState.signal('native');
  public $searchPanelVisible = this.appMainState.signal('searchPanelVisible');
  public $cmsContent = this.appMainState.signal('cmsContent');
  public $userVisibilityGroups = this.appMainState.signal('userVisibilityGroups');
  public $platformType = this.appMainState.signal('platformType');
  public $clientId = this.appMainState.signal('clientId');
  public $filterButtonState = this.appMainState.signal('filterButtonState');
  public $activeFilters = this.appMainState.signal('activeFilters');
  //#endregion

  //#region observables
  public user$ = this.appMainState.select('user');
  public initializationCompleted$ = this.appMainState.select('initializationCompleted');
  //#endregion  

  constructor(
    @Inject(APP_MAIN_RX_STATE) private appMainState: RxState<AppMainState>,
    private commonService: CommonService) {

  }

  setUser(user: IUser) {
    this.appMainState.set({ user: user });
  }

  setUserCars(cars: ICar[]) {
    this.appMainState.set({ userCars: cars });
  }

  setUserRfids(rfids: IRfid[]) {
    this.appMainState.set({ userRfids: rfids });
  }

  setAuthTokenUser(authToken: IAuthToken) {
    this.appMainState.set({ authTokenUser: authToken });
  }

  setSelectedInstallation(installation: IInstallation) {
    if (installation) {
      installation = this.commonService.sortInstallationStationsConnectors(installation);
    }
    this.appMainState.set(({ selectedInstallation: installation }));

    let stationDefault = installation?.stations[0];
    let stationLastSelectedConnector;
    let stationActiveSession;

    if (installation) {
      // provo a reimpostare l'ultimo connettore/stazione visualizzata
      const lastSelectedConnectorId = this.getLastSelectedConnectorId();
      if (lastSelectedConnectorId) {
        console.log("RXANG:setSelectedInstallation->lastSelectedConnectorId", lastSelectedConnectorId);
        let stationOfLastSelectedConnector = installation.stations.find(c => !!c.connectors.find(i => i.id === lastSelectedConnectorId));
        if (stationOfLastSelectedConnector) {
          console.log("RXANG:setSelectedInstallation->lastSelectedConnectorId->station found");
          stationLastSelectedConnector = stationOfLastSelectedConnector;
        }
      }

      // Se non è possibile, provo a mostrare quello associato ad una sessione
      if (!stationLastSelectedConnector) {
        console.log("RXANG:setSelectedInstallation->lastSelectedConnectorId->station NOT found");
        const sessionActive = this.getSessionByInstallation(installation?.id);
        if (sessionActive) {
          stationActiveSession = installation.stations.find(c => c.id == sessionActive.connector?.stationId);
        }
      }
    }
    this.setSelectedStation(stationLastSelectedConnector || stationActiveSession || stationDefault);
  }

  setSelectedStation(station: IStation) {
    this.appMainState.set(({ selectedStation: station }));

    let connectorDefault = station?.connectors[0];
    let connectorLastSelectedConnector;
    let connectorActiveSession;

    if (station) {
      // provo a reimpostare l'ultimo connettore visualizzato, se appartiene alla stazione
      const lastSelectedConnectorId = this.getLastSelectedConnectorId();
      if (lastSelectedConnectorId) {
        let lastSelectedConnector = station.connectors.find(i => i.id === lastSelectedConnectorId);
        if (lastSelectedConnector) {
          connectorLastSelectedConnector = lastSelectedConnector;
        }
      }

      // Se non è possibile, provo a mostrare quello associato ad una sessione attiva
      if (!connectorLastSelectedConnector) {
        const sessionActive = this.getSessionByStation(station.id);
        if (sessionActive) {
          connectorActiveSession = station.connectors.find(c => c.id === sessionActive.connectorId);
        }
      }
    }

    this.setSelectedConnector(connectorLastSelectedConnector || connectorActiveSession || connectorDefault);
  }

  setSelectedConnector(connector: IConnector) {
    this.appMainState.set(({ selectedConnector: connector }));
    if (connector) {
      this.setLastSelectedConnectorId(connector.id);
    }
  }

  setSelectedUserCarForSession(car: ICar) {
    this.appMainState.set(({ selectedUserCarForSession: car }));
  }

  setLastSelectedConnectorId(connectorId: number) {
    this.appMainState.set(({ lastSelectedConnectorId: connectorId }));
  }

  setSelectedCluster(installations: IInstallation[]) {
    this.appMainState.set(({ selectedCluster: installations }));
  }

  setSessions(sessions: ISession[]) {
    this.appMainState.set({ sessions: sessions });
  }

  setButtonStateMap(buttonStateMap: Map<number, ButtonState>) {
    this.appMainState.set({ buttonState: buttonStateMap });
  }

  setVersion(version: IVersionUpdate) {
    this.appMainState.set({ version: version });
  }

  setSearchPanelVisible(visibile: boolean) {
    this.appMainState.set({ searchPanelVisible: visibile });
  }

  setCmsContent(cmsContent: IInfoContent) {
    this.appMainState.set({ cmsContent: cmsContent });
  }

  setUserVisibilityGroups(uvg: IUserVisibilityGroups) {
    this.appMainState.set({ userVisibilityGroups: uvg });
  }

  setPlatformType(platform: string) {
    this.appMainState.set({ platformType: platform });
  }

  setClientId(clientId: string) {
    this.appMainState.set({ clientId: clientId });
  }

  setClientLastPosition(lastPosition: Point) {
    this.appMainState.set({ clientLastPosition: lastPosition });
  }

  setInitializationCompleted(initCompleted: boolean) {
    this.appMainState.set({ initializationCompleted: initCompleted });
  }

  setFilterButtonState(filterButtonState: CarFilters) {
    this.appMainState.set({ filterButtonState: filterButtonState });
  }

  async updateSessionsWithCurrent(session: ISession) {
    let updatedSessions = [];

    const existingSessions = this.getSessions() || [];
    const isUpdatingExistingSession = existingSessions?.find(c => c.id === session.id);

    // Scorro tutte le sessioni esistenti per riaggiungerle così come sono o aggiornare quella in questione
    for (let existSess of existingSessions) {
      // Se la sessione esisteva già e non è terminata, la inserisco fra quelle associate all'utente
      if (isUpdatingExistingSession && existSess.id === session.id) {
        if (!session.endedAt) {
          updatedSessions.push(session);
        }
      }
      // Le altre eventuali sessioni le reinserisco così come sono
      else {
        updatedSessions.push(existSess);
      }
    }

    // Si tratta di una sessione che non era salvata nello store: se è ancora in corso la aggiungo
    if (!isUpdatingExistingSession && !session.endedAt) {
      updatedSessions.push(session);
    }

    this.setSessions(updatedSessions);
  }

  updateButtonState(connectorId: number, connectorButtonState: ButtonState) {
    let buttonStateMap = this.getButtonState();
    if (!buttonStateMap) {
      buttonStateMap = new Map<number, ButtonState>();
    }
    buttonStateMap.set(connectorId, connectorButtonState);
    this.setButtonStateMap(buttonStateMap);
  }

  getSessionByStation(stationId: string): ISession {
    return this.appMainState.get('sessions')?.find(s => s?.connector.stationId === stationId);
  }

  getSessionByInstallation(installationId: string): ISession {
    return this.appMainState.get('sessions')?.find(s => s?.connector.station?.installationId === installationId);
  }

  getSessions(): ISession[] {
    return this.appMainState.get('sessions');
  }

  getSelectedCluster(): IInstallation[] {
    return this.appMainState.get('selectedCluster');
  }

  getSelectedInstallation(): IInstallation {
    return this.appMainState.get('selectedInstallation');
  }

  getSelectedStation(): IStation {
    return this.appMainState.get('selectedStation');
  }

  getSelectedConnector(): IConnector {
    return this.appMainState.get('selectedConnector');
  }

  getSelectedUserCarForSession(): ICar {
    return this.appMainState.get('selectedUserCarForSession');
  }

  getLastSelectedConnectorId(): number {
    return this.appMainState.get('lastSelectedConnectorId');
  }

  getUser(): IUser {
    return this.appMainState.get('user');
  }

  getUserCars(): ICar[] {
    return this.appMainState.get('userCars');
  }

  getUserRfids(): IRfid[] {
    return this.appMainState.get('userRfids');
  }

  getAuthTokenUser(): IAuthToken {
    return this.appMainState.get('authTokenUser');
  }

  getButtonState(): Map<number, ButtonState> {
    return this.appMainState.get('buttonState');
  }

  getVersion(): IVersionUpdate {
    return this.appMainState.get('version');
  }

  getNative(): boolean {
    return this.appMainState.get('native');
  }

  getSearchPanelVisible(): boolean {
    return this.appMainState.get('searchPanelVisible');
  }

  getCmsContent() {
    return this.appMainState.get('cmsContent');
  }

  getUserVisibilityGroups() {
    return this.appMainState.get('userVisibilityGroups');
  }

  getPlatformType() {
    return this.appMainState.get('platformType');
  }

  getClientId() {
    return this.appMainState.get('clientId');
  }

  getLastClientPosition() {
    return this.appMainState.get('clientLastPosition');
  }

  getInitializationCompleted() {
    return this.appMainState.get('initializationCompleted');
  }

  getFilterButtonState() {
    return this.appMainState.get('filterButtonState');
  }
}