// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --yf-border-radius: 12px;
  --yf-shadow: 5px 5px 10px #0000001f;
  --yf-spacing0: 6px;
  --yf-spacing1: 12px;
  --yf-spacing2: 24px;
  --yf-spacing3: 36px;
  --yf-spacing4: 48px;
  --yf-spacing5: 60px;
  --yf-primary-light: #9edaff;
  --yf-primary: #297eb2;
  --yf-primary-dark: #174c6d;
  --yf-secondary: #0d2c40;
  --yf-accent: #c2fc84;
  --yf-white: #FFFF;
}

.connector-button {
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-top: 0;
  padding: 0px 4px;
  height: auto;
  width: 108px;
  position: relative;
}
.connector-button::part(native) {
  height: fit-content;
}

.setOpacity {
  opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/theme/sass/abstracts/variables.scss","webpack://./src/app/components/station/station-connector/station-connector.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,mCAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EAEA,2BAAA;EACA,qBAAA;EACA,0BAAA;EACA,uBAAA;EACA,oBAAA;EACA,iBAAA;ACAF;;AAbA;EACE,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;AAgBF;AAfE;EACE,mBAAA;AAiBJ;;AAdA;EACE,YAAA;AAiBF","sourcesContent":[":root {\n  --yf-border-radius: 12px;\n  --yf-shadow: 5px 5px 10px #0000001f;\n  --yf-spacing0: 6px;\n  --yf-spacing1: 12px;\n  --yf-spacing2: 24px;\n  --yf-spacing3: 36px;\n  --yf-spacing4: 48px;\n  --yf-spacing5: 60px;\n\n  --yf-primary-light: #9edaff;\n  --yf-primary: #297eb2;\n  --yf-primary-dark: #174c6d;\n  --yf-secondary: #0d2c40;\n  --yf-accent: #c2fc84;\n  --yf-white: #FFFF;\n}\n","@import \"../../../../theme/sass/abstracts/variables.scss\";\n\n.connector-button {\n  --padding-start: 0;\n  --padding-end: 0;\n  --padding-bottom: 0;\n  --padding-top: 0;\n  padding: 0px 4px;\n  height: auto;\n  width: 108px;\n  position: relative;\n  &::part(native) {\n    height: fit-content;\n  }\n}\n.setOpacity{\n  opacity: 0.7;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
