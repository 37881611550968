import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Barcode,
  BarcodeFormat,
  BarcodeScanner,
  LensFacing,
  StartScanOptions,
} from '@capacitor-mlkit/barcode-scanning';
import { InputCustomEvent, ModalController } from '@ionic/angular';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-barcode-scanning',
  templateUrl: './barcode-scanning-modal.component.html',
  styleUrls: ['./barcode-scanning-modal.component.scss'],
  standalone: false,
})
export class BarcodeScanningModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public formats: BarcodeFormat[] = [];
  @Input() public lensFacing: LensFacing = LensFacing.Back;

  @ViewChild('square') public squareElement: ElementRef<HTMLDivElement> | undefined;

  isTorchAvailable = false;
  minZoomRatio: number | undefined;
  maxZoomRatio: number | undefined;

  text = '';
  constructor(
    private readonly ngZone: NgZone,
    private readonly modalController: ModalController,
    private appService: AppService,
  ) { }

  public ngOnInit(): void {
    this.appService.checkQrCodeKnownPatterns("https://smart.enelxway.it/?id=21XP22T3KK4AK170RJ*1");
    // BarcodeScanner.isTorchAvailable().then((result) => {
    //   this.isTorchAvailable = result.available;
    // });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      console.log("TESTONE")
      this.startScan();
    }, 500);
  }

  public ngOnDestroy(): void {
    this.stopScan();
  }

  public setZoomRatio(event: InputCustomEvent): void {
    if (!event.detail.value) {
      return;
    }
    BarcodeScanner.setZoomRatio({
      zoomRatio: parseInt(event.detail.value as any, 10),
    });
  }

  public async closeModal(data?: string): Promise<void> {
    this.modalController.dismiss({
      text: data ?? '',
    });
  }

  public async toggleTorch(): Promise<void> {
    await BarcodeScanner.toggleTorch();
  }

  private async startScan(): Promise<void> {
    // Hide everything behind the modal (see `src/theme/variables.scss`)
    document.querySelector('body')?.classList.add('barcode-scanning-active');

    const options: StartScanOptions = {
      formats: this.formats,
      lensFacing: this.lensFacing,
    };

    const squareElementBoundingClientRect =
      this.squareElement?.nativeElement.getBoundingClientRect();
    const scaledRect = squareElementBoundingClientRect
      ? {
        left: squareElementBoundingClientRect.left * window.devicePixelRatio,
        right:
          squareElementBoundingClientRect.right * window.devicePixelRatio,
        top: squareElementBoundingClientRect.top * window.devicePixelRatio,
        bottom:
          squareElementBoundingClientRect.bottom * window.devicePixelRatio,
        width:
          squareElementBoundingClientRect.width * window.devicePixelRatio,
        height:
          squareElementBoundingClientRect.height * window.devicePixelRatio,
      }
      : undefined;
    const detectionCornerPoints = scaledRect
      ? [
        [scaledRect.left, scaledRect.top],
        [scaledRect.left + scaledRect.width, scaledRect.top],
        [
          scaledRect.left + scaledRect.width,
          scaledRect.top + scaledRect.height,
        ],
        [scaledRect.left, scaledRect.top + scaledRect.height],
      ]
      : undefined;
    // const listener = await BarcodeScanner.addListener(
    //   'barcodeScanned',
    //   async (event) => {
    //     this.ngZone.run(() => {
    //       const cornerPoints = event.barcode.cornerPoints;
    //       if (detectionCornerPoints && cornerPoints) {
    //         if (
    //           detectionCornerPoints[0][0] > cornerPoints[0][0] ||
    //           detectionCornerPoints[0][1] > cornerPoints[0][1] ||
    //           detectionCornerPoints[1][0] < cornerPoints[1][0] ||
    //           detectionCornerPoints[1][1] > cornerPoints[1][1] ||
    //           detectionCornerPoints[2][0] < cornerPoints[2][0] ||
    //           detectionCornerPoints[2][1] < cornerPoints[2][1] ||
    //           detectionCornerPoints[3][0] > cornerPoints[3][0] ||
    //           detectionCornerPoints[3][1] < cornerPoints[3][1]
    //         ) {
    //           listener.remove();
    //           // this.closeModal(event.barcode);
    //           console.log('Barcode scanned:', event.barcode.displayValue, event.barcode);
    //           // this.text = event.barcode.displayValue;
    //           // this.appService.filterForKnownQrCodePatterns
    //           this.text = this.appService.checkQrCodeKnownPatterns(event.barcode.displayValue);
    //           return;
    //         }
    //       }
    //     });
    //   },
    // );
    const listener = await BarcodeScanner.addListener(
      'barcodeScanned',
      async (event) => {
        this.ngZone.run(async () => {
          console.log("Pasta")
          console.log('Barcode scanned trovato:', event.barcode.displayValue, event.barcode);
          this.text = this.appService.checkQrCodeKnownPatterns(event.barcode.displayValue);
          await listener.remove();
          // const cornerPoints = event.barcode.cornerPoints;
          // if (detectionCornerPoints && cornerPoints) {
          //   if (
          //     detectionCornerPoints[0][0] > cornerPoints[0][0] ||
          //     detectionCornerPoints[0][1] > cornerPoints[0][1] ||
          //     detectionCornerPoints[1][0] < cornerPoints[1][0] ||
          //     detectionCornerPoints[1][1] > cornerPoints[1][1] ||
          //     detectionCornerPoints[2][0] < cornerPoints[2][0] ||
          //     detectionCornerPoints[2][1] < cornerPoints[2][1] ||
          //     detectionCornerPoints[3][0] > cornerPoints[3][0] ||
          //     detectionCornerPoints[3][1] < cornerPoints[3][1]
          //   ) {
          //     return;
          //   }
          // }
          // return;
        });
      },
    );
    await BarcodeScanner.startScan(options);
    void BarcodeScanner.getMinZoomRatio().then((result) => {
      this.minZoomRatio = result.zoomRatio;
    });
    void BarcodeScanner.getMaxZoomRatio().then((result) => {
      this.maxZoomRatio = result.zoomRatio;
    });
  }

  async stopScan(): Promise<void> {
    document.querySelector('body')?.classList.remove('barcode-scanning-active');
    try {
      await BarcodeScanner.stopScan();
    } catch (e) {
      console.error("Error stopping scan", e);
    }
    // await this.modalController.dismiss({
    //   text: this.text,
    // });
    await this.closeModal(this.text);
  }
}
