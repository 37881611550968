"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OCPIGatewayMessage = void 0;
exports.OCPIGatewayMessage = {
  Installations: 'INSTALLATIONS',
  GetLocations: 'GET_LOCATIONS',
  UpdateSession: 'UPDATE_SESSION',
  GetSession: 'GET_SESSION',
  GtwHandshake: 'GTW_HANDSHAKE',
  GetMunicipalities: 'GET_MUNICIPALITIES',
  SendMeters: 'SEND_METERS',
  PostCdr: 'POST_CDR',
  GetCdr: 'GET_CDR',
  TokenAuth: 'TOKEN_AUTH',
  GetTariff: 'GET_TARIFF',
  PutTariff: 'PUT_TARIFF',
  DeleteTariff: 'DELETE_TARIFF',
  PaginatedTokens: 'PAGINATED_TOKENS',
  SendStatusNotification: 'SEND_STATUS_NOTIFICATION',
  GetConnector: 'GET_CONNECTOR',
  StartSession: "START_SESSION",
  StopSession: "STOP_SESSION",
  StartReservation: "RESERVE_NOW",
  StopReservation: "STOP_RESERVATION",
  SendTokens: "SEND_TOKENS",
  GetToken: "GET_TOKEN",
  SetCommandResult: "SET_COMMAND_RESULT"
};
