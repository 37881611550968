import { Component, EventEmitter, OnInit, Output, NgZone } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';
import { ModalController } from '@ionic/angular';
import { IUpdateUserDTO, IUser, IUpdatePassword, ICountry, UserType, PlatformType } from 'common_library';
import { BehaviorSubject } from 'rxjs';
import { MyFormlyHelpers } from 'src/app/my-formly/my-formly.helpers';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpIoService } from 'src/app/services/communication/http-io.service';
import { UserService } from 'src/app/services/entities/user.service';
import { ToastService } from 'src/app/services/utils/toast.service';
import { ProfileDeletionComponent } from '../profile-deletion/profile-deletion.component';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

type UserFormType = {
  email: string;
  name?: string;
  surname?: string;
};

// type SettingsFormType = {
//   disabled?: boolean;
//   canUploadPhotos?: boolean;
//   canReview?: boolean;
// };

type passwordChangeType = {
  oldPassword?: string;
  password?: string;
  passwordConfirm?: string;
};


@Component({
  selector: 'yf-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit {
  @Output() userUpdated = new EventEmitter();

  constructor(
    public modalCtrl: ModalController,
    public auth: AuthService,
    private toast: ToastService,
    private userService: UserService,
    private HIO: HttpIoService,
    private ngZone: NgZone,
    private mainStateService: MainStateService,
    private translate: TranslateService
  ) {
    if (this.mainStateService.getNative()) {
      this.listenKeyboardVisibility();
    }
  }

  user: IUser;
  userFrm: MyFormlyHelpers.FormlyForm<Partial<IUser>>;
  passwordFrm: MyFormlyHelpers.FormlyForm<passwordChangeType>;
  userInfoFrm: MyFormlyHelpers.FormlyForm<Partial<IUser>>
  countries$ = new BehaviorSubject<ICountry[]>([]);
  userTypes$ = new BehaviorSubject<{ name: string }[]>([]);
  footerVisible: boolean = true;
  doesUserWantsInvoice: boolean;
  isInvoiceCheckBoxDirty: boolean = false;

  async ngOnInit() {
    this.user = this.mainStateService.getUser();
    const countries = await this.HIO.get<ICountry[]>('geo/countries');
    this.countries$.next(countries);
    
    this.userTypes$.next([
      { name: UserType.PRIVATO },
      { name: UserType.PARTITA_IVA.split("_").join(" ") },
    ])

    if (this.user) {
      this.buildUserForm();
      this.doesUserWantsInvoice = this.user.wantsInvoice;
      this.buildUserInfoForm();
      this.buildPasswordForm();
    }
  }

  async update() {
    if (!this.userFrm.form.valid && !this.passwordFrm.form.valid && !this.userInfoFrm.form.valid) {
      return;
    }
    await this.updateUser();
    await this.updateUserInfo();
    await this.updatePassword();
  }

  cancel() {
    this.userFrm.options.resetModel()
    this.passwordFrm.options.resetModel()
    this.userInfoFrm.options.resetModel();
  }

  listenKeyboardVisibility() {
    Keyboard.addListener('keyboardWillShow', () => {
      this.ngZone.run(() => {
        this.footerVisible = false;
      });
    });

    Keyboard.addListener('keyboardDidHide', () => {
      this.ngZone.run(() => {
        this.footerVisible = true;
      });
    });
  }

  async deleteProfile() {
    await this.modalCtrl.dismiss();

    const modal = await this.modalCtrl.create({
      component: ProfileDeletionComponent,
      cssClass: 'login-modal',
      componentProps: { user: this.user ? this.user : null }
    });

    await modal.present();
  }

  buildUserForm() {
    this.userFrm = new MyFormlyHelpers.FormlyForm<Partial<IUser>>(
      {
        email: this.user.email,
        name: this.user.name.trim(),
        surname: this.user.surname.trim(),
      },
      [
        MyFormlyHelpers.emailField({
          disabled: true,
        }),
        MyFormlyHelpers.inputField('name', this.translate.instant("PROFILE.USER_DETAIL.NAME")),
        MyFormlyHelpers.inputField('surname', this.translate.instant("PROFILE.USER_DETAIL.SURNAME")),
      ]
    );
  }

  buildPasswordForm() {
    this.passwordFrm = new MyFormlyHelpers.FormlyForm<passwordChangeType>(
      {
        oldPassword: '',
        password: '',
        passwordConfirm: '',
      },
      [MyFormlyHelpers.confirmPasswordFields(false, this.translate.instant('BUTTONS.CONFIRM'))]
    );
  }

  buildUserInfoForm() {
    //const userType = this.doesUserWantsInvoice ? UserType.PARTITA_IVA : UserType.PRIVATO;
    this.userInfoFrm = new MyFormlyHelpers.FormlyForm<Partial<IUser>>({
      //userType: userType.split("_").join(" ") as UserTypeValues,
      taxCode: this.user.taxCode,
      pIva: this.user.pIva,
      pIvaUniCode: this.user.pIvaUniCode,
      addressStreet: this.user.addressStreet,
      addressNumber: this.user.addressNumber,
      city: this.user.city,
      province: this.user.province,
      postalCode: this.user.postalCode,
      countryId: this.user.countryId,
    },
      [
        //MyFormlyHelpers.selectField('userType', this.translate.instant("PROFILE.USER_DETAIL.TYPE"), { options: this.userTypes$ }, { hide: 'true' }, userType),
        MyFormlyHelpers.inputField('taxCode', this.translate.instant("PROFILE.USER_DETAIL.TAX_CODE")),// 'model.userType === "PARTITA IVA"', this.user.taxCode),
        MyFormlyHelpers.inputField('pIva', this.translate.instant("PROFILE.USER_DETAIL.VAT_NUMBER")),// 'model.userType === "PRIVATO"', this.user.pIva),
        MyFormlyHelpers.inputField('pIvaUniCode', this.translate.instant("PROFILE.USER_DETAIL.BILLING_CODE"), false),// 'model.userType === "PRIVATO"', this.user.pIvaUniCode),
        MyFormlyHelpers.inputField('addressStreet', this.translate.instant("PROFILE.USER_DETAIL.STREET")),
        MyFormlyHelpers.inputField('addressNumber', this.translate.instant("PROFILE.USER_DETAIL.HOUSE_NUMBER")),
        MyFormlyHelpers.inputField('city', this.translate.instant("PROFILE.USER_DETAIL.CITY")),
        MyFormlyHelpers.inputField('province', this.translate.instant("PROFILE.USER_DETAIL.PROVINCE")),
        MyFormlyHelpers.inputField('postalCode', this.translate.instant("PROFILE.USER_DETAIL.ZIP_CODE")),
        MyFormlyHelpers.selectField('countryId', this.translate.instant("PROFILE.USER_DETAIL.COUNTRY"), { options: this.countries$ }),
      ]
    )
  }

  checkboxChange() {
    this.isInvoiceCheckBoxDirty = true;
    this.buildUserInfoForm();
  }

  async updateUser() {
    try {
      if (this.userFrm.form.dirty) {
        let dto: IUpdateUserDTO = {
          email: this.userFrm.model.email,
          name: this.userFrm.model.name.trim(),
          surname: this.userFrm.model.surname.trim(),
        };
        const user = await this.userService.updateMe(dto);
        this.mainStateService.setUser(user);
        this.user = user;
        this.buildUserForm();
        this.toast.presentBasicToast({
          message: this.translate.instant("PROFILE.USER_EDIT.UPDATED"),
          color: 'secondary',
          icon: 'person-outline'
        });
      }
    } catch (err) {
      console.error("errore aggiornando utente -> ", err)
      this.toast.presentBasicToast({ message: this.translate.instant("PROFILE.USER_EDIT.CANT_UPDATE"), color: 'danger', icon: 'warning' });
    }
  }

  async updateUserInfo() {
    try {
      if (this.userInfoFrm.form.dirty || this.isInvoiceCheckBoxDirty) {
        let dto: IUpdateUserDTO;

        if (!this.doesUserWantsInvoice) {
          dto = {
            //userType: userType,
            addressNumber: null,
            addressStreet: null,
            pIva: null,
            pIvaUniCode: null,
            taxCode: null,
            postalCode: null,
            city: null,
            province: null,
            countryId: null,
            wantsInvoice: false,
          };
        } else {
          dto = {
            taxCode: this.userInfoFrm.model.taxCode?.trim(), //userType === UserType.PRIVATO ? this.userInfoFrm.model.taxCode?.trim() : null,
            pIva: this.userInfoFrm.model.pIva?.trim(), //userType === UserType.PARTITA_IVA ? this.userInfoFrm.model.pIva?.trim() : null,
            pIvaUniCode: this.userInfoFrm.model.pIvaUniCode?.trim(), //userType === UserType.PARTITA_IVA ? this.userInfoFrm.model.pIvaUniCode?.trim() : null,
            addressStreet: this.userInfoFrm.model.addressStreet?.trim(),
            addressNumber: this.userInfoFrm.model.addressNumber?.trim(),
            postalCode: this.userInfoFrm.model.postalCode?.trim(),
            city: this.userInfoFrm.model.city?.trim(),
            province: this.userInfoFrm.model.province?.trim(),
            countryId: this.userInfoFrm.model.countryId,
            wantsInvoice: true,
          }
        }

        const user = await this.userService.updateMe(dto);
        this.isInvoiceCheckBoxDirty = false;
        this.mainStateService.setUser(user);
        this.userUpdated.emit();
        this.user = user;
        this.buildUserInfoForm();
        this.toast.presentBasicToast({
          message: this.translate.instant("PROFILE.USER_EDIT.UPDATED_BILLING"),
          color: 'secondary',
          icon: 'person-outline'
        });
      }
    } catch (err) {
      console.error("errore aggiornando billing info -> ", err)
      this.toast.presentBasicToast({ message: this.translate.instant("PROFILE.USER_EDIT.CANT_UPDATE_BILLING"), color: 'danger', icon: 'warning' });
    }
  }

  async updatePassword() {
    if (this.passwordFrm.form.valid && !(this.passwordFrm.model.passwordConfirm === '')) {
      try {
        const dto: IUpdatePassword = {
          email: this.userFrm.model.email,
          password: this.passwordFrm.model.passwordConfirm,
        };
        const rv = await this.auth.changePassword(dto.email, dto.password);
        if (rv) {
          this.buildPasswordForm();
          this.toast.presentBasicToast({
            message: this.translate.instant("PROFILE.USER_EDIT.UPDATED_PASSWORD"),
            color: 'secondary',
            icon: 'person-outline'
          });
        }
        else {
          this.toast.presentBasicToast({ message: this.translate.instant("PROFILE.USER_EDIT.CANT_UPDATE_PASSWORD"), color: 'danger', icon: 'warning' });
        }
      } catch (err) {
        this.toast.presentBasicToast({ message: this.translate.instant("PROFILE.USER_EDIT.CANT_UPDATE_PASSWORD"), color: 'danger', icon: 'warning' });
      }
    }
  }
}
