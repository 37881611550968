"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CU = void 0;
var authType_enum_1 = require("./enums/authType.enum");
var select_enum_1 = require("./enums/select.enum");
var state_enum_1 = require("./enums/state.enum");
var CU;
(function (CU) {
  CU.GenerateRandomString = function (length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));
    return result;
  };
  function ToArray(enumObj) {
    return Object.keys(enumObj).map(function (key) {
      return {
        id: key,
        name: enumObj[key]
      };
    });
  }
  CU.ToArray = ToArray;
  function EnumKeys(en) {
    return Object.keys(en).filter(function (k) {
      return Number.isNaN(+k);
    });
  }
  CU.EnumKeys = EnumKeys;
  function EnumValues(en) {
    return EnumKeys(en).map(function (k) {
      return en[k];
    });
  }
  CU.EnumValues = EnumValues;
  function EnumKeyByEnumValue(myEnum, enumValue) {
    var keys = Object.keys(myEnum).filter(function (x) {
      return myEnum[x] == enumValue;
    });
    return keys.length > 0 ? keys[0] : null;
  }
  CU.EnumKeyByEnumValue = EnumKeyByEnumValue;
  function itDate(date) {
    if (date === void 0) {
      date = new Date();
    }
    var dt = date.toLocaleString('it-IT', {
      timeZone: 'Europe/Rome'
    });
    var yyyy = date.getFullYear();
    var mm = (date.getMonth() + 1).toString(); // Months start at 0!
    var dd = date.getDate().toString();
    if (dd.length === 1) dd = '0' + dd;
    if (mm.length === 1) mm = '0' + mm;
    return yyyy + '-' + mm + '-' + dd + " " + dt.split(" ")[1];
  }
  CU.itDate = itDate;
  function replaceAll(str, find, replace) {
    return str.split(find).join(replace);
  }
  CU.replaceAll = replaceAll;
  function isEqual(o1, o2) {
    return JSON.stringify(o1) === JSON.stringify(o2);
  }
  CU.isEqual = isEqual;
  function isNullOrUndefined(val) {
    return val === undefined || val == null ? true : false;
  }
  CU.isNullOrUndefined = isNullOrUndefined;
  function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }
  CU.addMinutes = addMinutes;
  function secsToHHMM(secs) {
    return new Date(secs * 1000).toISOString().substring(11, 16);
  }
  CU.secsToHHMM = secsToHHMM;
  function idTagFromSessionId(sid) {
    var _a;
    return (_a = replaceAll(sid, '-', '').substring(0, 14)) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase();
  }
  CU.idTagFromSessionId = idTagFromSessionId;
  function timeSpan(fromDt, toDt, text) {
    var date_start = new Date(fromDt).getTime();
    var date_end = new Date(toDt).getTime();
    var d = Math.abs(date_end - date_start) / 1000; // delta
    var r = {}; // result
    var s = {
      year: 31536000,
      mounth: 2592000,
      week: 604800,
      // uncomment row to ignore
      d: 86400,
      // feel free to add your own row
      h: 3600,
      m: 60,
      s: 1
    };
    Object.keys(s).forEach(function (key) {
      r[key] = Math.floor(d / s[key]);
      d -= r[key] * s[key];
    });
    if (text) {
      return transformDate(r);
    }
    return syntheticDate(r);
  }
  CU.timeSpan = timeSpan;
  function transformDate(timer) {
    var time = [];
    if (timer.d > 0) {
      time.push({
        type: 'd',
        value: timer.d
      });
      time.push({
        type: 'h',
        value: timer.h
      });
      return "".concat(timer.d, " d ").concat(timer.h, " h");
    } else if (timer.h > 0) {
      time.push({
        type: 'h',
        value: timer.h
      });
      time.push({
        type: 'm',
        value: timer.m
      });
      return "".concat(timer.h, " h ").concat(timer.m, " m");
    }
    time.push({
      type: 'm',
      value: timer.m
    });
    time.push({
      type: 's',
      value: timer.s
    });
    return "".concat(timer.m, "m ").concat(timer.s, "s");
  }
  function syntheticDate(timer) {
    var test = [];
    if (timer.d > 0) {
      test.push({
        type: 'd',
        value: timer.d
      });
      test.push({
        type: 'h',
        value: timer.h
      });
      return test;
    } else if (timer.h > 0) {
      test.push({
        type: 'h',
        value: timer.h
      });
      test.push({
        type: 'm',
        value: timer.m
      });
      return test;
    }
    test.push({
      type: 'm',
      value: timer.m
    });
    test.push({
      type: 's',
      value: timer.s
    });
    return test;
  }
  function timeSince(date) {
    if (date === void 0) {
      date = new Date();
    }
    var dt = new Date(date).getTime();
    var seconds = Math.floor((Date.now() - dt) / 1000);
    return humanReadableTime(seconds);
  }
  CU.timeSince = timeSince;
  function humanReadableTime(seconds) {
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " anni";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " mesi";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " giorni";
    }
    interval = seconds / 3600;
    if (interval >= 1 && interval < 2) {
      return Math.floor(interval) + " ora";
    }
    if (interval > 1) {
      return Math.floor(interval) + " ore";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minuti";
    }
    return Math.floor(seconds) + " secondi";
  }
  CU.humanReadableTime = humanReadableTime;
  function formattedCountdown(endDate) {
    var dDay = endDate.valueOf();
    var milliSecondsInASecond = 1000;
    var hoursInADay = 24;
    var minutesInAnHour = 60;
    var secondsInAMinute = 60;
    var timeDifference = dDay - Date.now();
    var hours = Math.floor(timeDifference / (milliSecondsInASecond * minutesInAnHour * secondsInAMinute) % hoursInADay);
    var minutes = Math.floor(timeDifference / (milliSecondsInASecond * minutesInAnHour) % secondsInAMinute);
    var seconds = Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;
    if (hours < 0 || minutes < 0 || seconds < 0) return '00:00';
    var minutesPadded = minutes.toLocaleString('it-IT', {
      minimumIntegerDigits: 2
    });
    var secondsPadded = seconds.toLocaleString('it-IT', {
      minimumIntegerDigits: 2
    });
    if (hours > 0) return "".concat(hours, ":").concat(minutesPadded, ":").concat(secondsPadded);
    return "".concat(minutesPadded, ":").concat(secondsPadded);
  }
  CU.formattedCountdown = formattedCountdown;
  function getYearMonthDescription(date) {
    if (date === void 0) {
      date = new Date();
    }
    return date.toLocaleString('it-IT', {
      year: 'numeric',
      month: 'long'
    });
  }
  CU.getYearMonthDescription = getYearMonthDescription;
  function getDayDiff(startDate, endDate) {
    var msInDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay);
  }
  CU.getDayDiff = getDayDiff;
  function getDateDiffInSeconds(startDate, endDate) {
    return Math.round(Math.abs(startDate.getTime() - endDate.getTime()) / 1000);
  }
  CU.getDateDiffInSeconds = getDateDiffInSeconds;
  function getDateDiffInSecondsWithOneOnZero(startDate, endDate) {
    var diffInSeconds = Math.round(Math.abs(startDate.getTime() - endDate.getTime()) / 1000);
    return diffInSeconds === 0 ? diffInSeconds + 1 : diffInSeconds;
  }
  CU.getDateDiffInSecondsWithOneOnZero = getDateDiffInSecondsWithOneOnZero;
  function getDateDiffInMinutes(startDate, endDate) {
    return Math.round((startDate.getTime() - endDate.getTime()) / 60000);
  }
  CU.getDateDiffInMinutes = getDateDiffInMinutes;
  function stringIsUtcDate(text) {
    var regexpDate = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\\.[0-9]+)?(Z)?$');
    return regexpDate.test(text);
  }
  CU.stringIsUtcDate = stringIsUtcDate;
  function calcPriceRound(value) {
    return Math.round(value * 100) / 100;
  }
  CU.calcPriceRound = calcPriceRound;
  function roundNumber(value, places) {
    if (places === void 0) {
      places = 2;
    }
    return parseFloat(value.toFixed(places));
  }
  CU.roundNumber = roundNumber;
  function getLogoByCdcType(cdcType) {
    if (cdcType === "VISA") {
      return "/assets/logos/cdc/cdc_visa.png";
    }
    if (cdcType === "MASTERCARD") {
      return "/assets/logos/cdc/cdc_mastercard.png";
    }
    return "/assets/logos/cdc/cdc_generic.png";
  }
  CU.getLogoByCdcType = getLogoByCdcType;
  function isIpAddress(txt) {
    return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(txt);
  }
  CU.isIpAddress = isIpAddress;
  function formatHours(hours) {
    var h = Math.floor(hours);
    var m = Math.floor((hours - h) * 60);
    var s = Math.floor(((hours - h) * 60 - m) * 60);
    return "".concat(h ? h + 'h' : '', " ").concat(m ? m + 'm' : '', " ").concat(s ? s + 's' : '');
  }
  CU.formatHours = formatHours;
  function formatDateMySond(objectDate) {
    var seconds = objectDate.getSeconds().toLocaleString('it-IT', {
      minimumIntegerDigits: 2
    });
    var minutes = objectDate.getMinutes().toLocaleString('it-IT', {
      minimumIntegerDigits: 2
    });
    var hours = objectDate.getHours().toLocaleString('it-IT', {
      minimumIntegerDigits: 2
    });
    var day = objectDate.getDate().toLocaleString('it-IT', {
      minimumIntegerDigits: 2
    });
    var month = (objectDate.getMonth() + 1).toLocaleString('it-IT', {
      minimumIntegerDigits: 2
    });
    var year = objectDate.getFullYear();
    return "".concat(year, "-").concat(month, "-").concat(day, "T").concat(hours, ":").concat(minutes, ":").concat(seconds);
  }
  CU.formatDateMySond = formatDateMySond;
  function formatDateYYYY_MM_DD_hh_mm_ss(objectDate) {
    var seconds = objectDate.getSeconds().toLocaleString('it-IT', {
      minimumIntegerDigits: 2
    });
    var minutes = objectDate.getMinutes().toLocaleString('it-IT', {
      minimumIntegerDigits: 2
    });
    var hours = objectDate.getHours().toLocaleString('it-IT', {
      minimumIntegerDigits: 2
    });
    var day = objectDate.getDate().toLocaleString('it-IT', {
      minimumIntegerDigits: 2
    });
    var month = (objectDate.getMonth() + 1).toLocaleString('it-IT', {
      minimumIntegerDigits: 2
    });
    var year = objectDate.getFullYear();
    return "".concat(year, "_").concat(month, "_").concat(day, "_").concat(hours, "_").concat(minutes, "_").concat(seconds);
  }
  CU.formatDateYYYY_MM_DD_hh_mm_ss = formatDateYYYY_MM_DD_hh_mm_ss;
  function formatDateYYYY_MM_DD(objectDate) {
    var day = objectDate.getDate().toLocaleString('it-IT', {
      minimumIntegerDigits: 2
    });
    var month = (objectDate.getMonth() + 1).toLocaleString('it-IT', {
      minimumIntegerDigits: 2
    });
    var year = objectDate.getFullYear();
    return "".concat(year, "_").concat(month, "_").concat(day);
  }
  CU.formatDateYYYY_MM_DD = formatDateYYYY_MM_DD;
  function getPriceWithVat(price, vat) {
    if (vat === void 0) {
      vat = 0.22;
    }
    if (price === 0) return 0;
    if (!price) return 0;
    price += price * vat / 100;
    return price;
  }
  CU.getPriceWithVat = getPriceWithVat;
  function calcPriceWithVat(netPrice, vat) {
    if (vat === void 0) {
      vat = 22;
    }
    var vatMultiplier = vat / 100 + 1;
    return netPrice * vatMultiplier;
  }
  CU.calcPriceWithVat = calcPriceWithVat;
  function calcPriceWithoutVat(priceWithVat, vat) {
    if (vat === void 0) {
      vat = 22;
    }
    var vatDivider = (vat + 100) / 100;
    return priceWithVat / vatDivider;
  }
  CU.calcPriceWithoutVat = calcPriceWithoutVat;
  function convertNumericStrings(obj) {
    if (typeof obj === 'string' && /^\d+(\.\d+)?$/.test(obj)) {
      return Number(obj);
    } else if (Array.isArray(obj)) {
      return obj.map(function (item) {
        return convertNumericStrings(item);
      });
    } else if (typeof obj === 'object') {
      var newObj = {};
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          newObj[key] = convertNumericStrings(obj[key]);
        }
      }
      return newObj;
    }
    return obj;
  }
  CU.convertNumericStrings = convertNumericStrings;
  function dateAIsBigger(dateA, dateB) {
    return dateA.getTime() > dateB.getTime();
  }
  CU.dateAIsBigger = dateAIsBigger;
  function capitalizeFirstLetter(textToTransform) {
    if (textToTransform) {
      return textToTransform.charAt(0).toUpperCase() + textToTransform.slice(1);
    } else return "";
  }
  CU.capitalizeFirstLetter = capitalizeFirstLetter;
  function authTypeToText(auth) {
    switch (auth) {
      case authType_enum_1.AuthType.APP_USER:
        return "applicazione";
      case authType_enum_1.AuthType.RFID:
        return "RFID";
      case authType_enum_1.AuthType.ADMIN:
        return "Amministratore backoffice";
      // dei seguenti casi sottostanti non ci sarebbe bisogno, ritorniamo stringa vuota per far sì che l'utente non legga "undefined"
      case authType_enum_1.AuthType.OTHER:
        return "";
      case authType_enum_1.AuthType.AD_HOC_USER:
        return "";
    }
  }
  CU.authTypeToText = authTypeToText;
  function getColorByState(state) {
    switch (state) {
      case state_enum_1.State.RESERVED:
        return {
          'background-color': '#c2fc84',
          color: '#0d2c40'
        };
      case state_enum_1.State.AVAILABLE:
        return {
          'background-color': '#297eb2',
          color: '#fcfcff'
        };
      case state_enum_1.State.CHARGING:
        return {
          'background-color': '#ffe121',
          color: '#0d2c40'
        };
      case state_enum_1.State.OCCUPIED:
        return {
          'background-color': '#e92626',
          color: '#fcfcff'
        };
      case state_enum_1.State.UNAVAILABLE:
        return {
          'background-color': '#a2a5ad',
          color: '#fcfcff'
        };
      default:
        return {
          'background-color': '#297eb2',
          color: '#fcfcff'
        };
    }
  }
  CU.getColorByState = getColorByState;
  function removeNestedNullUndefined(obj) {
    for (var key in obj) {
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        removeNestedNullUndefined(obj[key]);
      }
    }
  }
  CU.removeNestedNullUndefined = removeNestedNullUndefined;
  function dateCalcToArrayOfDates(time) {
    var now = new Date();
    var daysOfCurrentWeek = now.getDay();
    switch (time) {
      case select_enum_1.SelectSessionTime.TODAY:
        var startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
        return {
          startTime: startTime,
          endTime: now
        };
      case select_enum_1.SelectSessionTime.YESTERDAY:
        var yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
        var yesterdayStartTime = new Date(new Date(yesterday).setHours(0, 0, 0));
        var yesterdayEndTime = new Date(new Date(yesterday).setHours(23, 59, 59));
        return {
          startTime: yesterdayStartTime,
          endTime: yesterdayEndTime
        };
      case select_enum_1.SelectSessionTime.THIS_WEEK:
        var startCurrentWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - daysOfCurrentWeek + 1);
        return {
          startTime: startCurrentWeek,
          endTime: now
        };
      case select_enum_1.SelectSessionTime.LAST_WEEK:
        var endLastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - daysOfCurrentWeek, 23, 59, 59);
        var startLastWeek = new Date(endLastWeek.getFullYear(), endLastWeek.getMonth(), endLastWeek.getDate() - 6, 0, 0, 0);
        return {
          startTime: startLastWeek,
          endTime: endLastWeek
        };
    }
  }
  CU.dateCalcToArrayOfDates = dateCalcToArrayOfDates;
  function delay(ms) {
    return new Promise(function (resolve) {
      return setTimeout(resolve, ms);
    });
  }
  CU.delay = delay;
  CU.cleanAndSortObject = function (obj) {
    if (obj === null || obj === undefined) return undefined;
    if (Array.isArray(obj)) {
      return obj.map(CU.cleanAndSortObject).filter(function (item) {
        return item !== undefined;
      });
    }
    if (typeof obj === "object") {
      return Object.keys(obj).sort().reduce(function (acc, key) {
        var cleanedValue = CU.cleanAndSortObject(obj[key]);
        if (cleanedValue !== undefined) {
          acc[key] = cleanedValue;
        }
        return acc;
      }, {});
    }
    return obj;
  };
  function isEmptyObject(obj) {
    return obj !== null && obj !== undefined && Object.keys(obj).length === 0;
  }
  CU.isEmptyObject = isEmptyObject;
})(CU || (exports.CU = CU = {}));
