export const _ = {
  VERSION: '1.315',
  MSxMINUTE: 60000,
  USER_GROUPS_VISIBILITY_CACHE_SECONDS: 5,
  // API_URL:'http://localhost:3000',
  // AUTH_URL: '/auth',
  EMAIL_KEY: '_YF_EMAIL',
  CLIENT_KEY: '_YF_CLIENT',
  AUTHSTATE_KEY: '_YF_AUTHSTATE',
  GEOLOCATION_KEY: '_YF_GEOLOCATION',
  FAVOURITE_CAR_KEY: '_YF_FAVOURITE_CAR',
  VERSION_KEY: '_YF_VERSION',
  MIN_SUPPORTED_VERSION_KEY: '_YF_VERSION',
  SELECTED_FILTER: '_YF_SELECTED_FILTER',
  RECENT_SEARCHES: '_YF_RECENT_SEARCHES',
  CHAPTERS_KEY: '_YF_CHAPTERS',
  SESSION_HISTORY: '_YF_SESSION_HISTORY',
  LANG_KEY: '_YF_LANG',
  OCPI_SESSION_TIMEOUT: 60,
};
