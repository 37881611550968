import { computed, inject, Inject, Injectable, Signal } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { IClientSocket, WsNamespaces, PlatformType, PlugType, CarFilters, IVersionUpdate, IUserLanguage } from 'common_library';
import { BehaviorSubject, filter, first, fromEvent, map, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { _ } from '../consts';
import { SocketIoService } from './communication/socket-io.service';
import { StoreService } from './utils/store.service';
import { Geolocation, Geoposition } from '@awesome-cordova-plugins/geolocation/ngx';
import { RxState } from '@rx-angular/state';
import { GLOBAL_RX_STATE, GlobalState } from '../app.module';
import { HttpIoService } from './communication/http-io.service';
import { AppNativeService } from './app.native.service';
import { ToastService } from './utils/toast.service';
import { Router } from '@angular/router';
import { AppInfoComponent } from '../components/master-details-app-info/app-info.component';
import { ModalChapterUpdatedComponent } from '../components/modal-chapter-updated/modal-chapter-updated.component';
import { TranslateService } from '@ngx-translate/core';
import { MainState } from '../types/state/app-main-state.interface';
import { MainStateService } from './state/app-main-state.service';
import { Point } from 'geojson';
import { GeoLocationNativeService } from './native/geo-location-native.service';
import { DEFAULT_FILTER } from '../pages/filter/filter.page';
import { take } from 'lodash';
import { UserService } from './entities/user.service';
import { Language } from 'common_library/dist/user.interface';

const HEARTBEAT_MS = 300000; // 5 minuti

export enum ModalSource {
  Login = 'login',
  Registration = 'registration',
}

@Injectable({
  providedIn: 'root',
})
export class AppService {

  public apiUrl: string;

  public readonly returnUrl: string | null;
  visible$ = new BehaviorSubject<boolean>(true);
  private qrCodePatternFunctions: Function[] = [
    // enelxQrCodeFunction,
    // enelxStandardEvseId,
    // enelxStandardEvseIdWithConnector
  ];
  private qrCodeTextPatternFunctions: Function[] = [
    // enelxStringWithStarConnectorId,
    // checkString
  ];  private readonly userService = inject(UserService);
  checkForNativeUpdates$ = new BehaviorSubject<boolean>(false);
  $filterState: Signal<CarFilters> = this.mainStateService.$filterButtonState;

  private _hasGeoloc = false;
  get hasGeoloc(): boolean { return this._hasGeoloc; }

  waitingToastData: BehaviorSubject<{
    isProgression: boolean,
    message?: string,
    duration?: number
  }> = new BehaviorSubject({ isProgression: false });
  outOfView: boolean = false;
  translateService = inject(TranslateService);

  $login = new Subject<void>();
  $registration = new Subject<void>();


  $text = computed(() => { // Questa decisione non mi piace, prima o poi la cambio :(
    const filter = this.$filterState();
    // La domanda cruciale qua è perchè ci sta 1 invece che 0 sui CPO.
    // Perchè nei CPO al momento è incluso anche "???", e perchè non ci sta ? su plugs e range, perchè sono sempre valorizzati
    if (filter.plugs.length === 0 && filter.range.lower === filter.range.upper && filter.cpos.length === 1) {
      return 'APP.FILTER.NO_RESULT';
    } else if(filter.plugs.length === 0 && filter.cpos.length === 1) {
      return 'APP.FILTER.CONNECTOR_CPO';
    } else if(filter.plugs.length === 0) {
      return 'APP.FILTER.CONNECTOR';
    } else if(filter.cpos.length === 1) {
      return 'APP.FILTER.MISSING_CPO';
    }
  });

  constructor(
    @Inject(GLOBAL_RX_STATE) private globalState: RxState<GlobalState>,
    private HIO: HttpIoService,
    public platform: Platform,
    public SIS: SocketIoService,
    @Inject('APP_START_LOCATION') private appStartLocation: string,

    private store: StoreService,
    public geolocation: Geolocation,
    private geoLocationNative: GeoLocationNativeService,
    private appNativeService: AppNativeService,
    private mainStateService: MainStateService,
    private toast: ToastService,
    private modalCtrl: ModalController,
    private router: Router,
    private translate: TranslateService
  ) {

    // ###########################################################################################################################
    // aggiorno un proprietà dello stato globale che mi informa se l'app è visibile oppure no
    const visible = fromEvent(document, 'visibilitychange').pipe(map((event) => document.visibilityState === 'visible'));
    this.globalState.connect("visible", visible);

    // ###########################################################################################################################
    // PROCEDURA DI PULIZIA DELL'APP SE RISVEGLIATA DA UN PERIODO DI INATTIVITA'
    // nel mobile i tab del browser possono essere congelati o riesumati per salvare risorse di cpu e memoria
    // quando viene riesumata una web app spesso va in crisi
    // con questo controllo ci accorgiamo che l'app è appena stata riesumata e la facciamo ripartire da zero (window.location.reload)
    let ts = new Date().getTime();
    setInterval(() => {
      if ((new Date().getTime() - ts) > 2 * HEARTBEAT_MS) {
        console.log('[${NAME}] Wake-up from sleep detected');
        window.location.reload();
      }
      ts = new Date().getTime();
    }, HEARTBEAT_MS);
    // ###########################################################################################################################

    const backOnlineEvent$ = this.globalState.select("mainState").pipe(filter(ms => ms === MainState.ONLINE));

    // Ogni volta che torno online aggiorno l'appVersion (in effetti alcune volte torno online perchè è stato fatto un aggiornamento)
    backOnlineEvent$.pipe(
      tap(async () => {
        try {
          let versionUpdate = await this.HIO.get<IVersionUpdate>('versionUpdate');
          versionUpdate.frontendVersion = _.VERSION;
          if (this.mainStateService.getNative()) {
            try {
              versionUpdate.frontendVersion = await this.appNativeService.getCurrentAppVersionName();
            } catch (ex) {
              console.log("Errore version update:", ex);
            }
          }
          this.mainStateService.setVersion(versionUpdate);
          const precVersion = this.store.get<string>(_.VERSION_KEY);
          if (versionUpdate.frontendVersion !== precVersion) {
            this.store.set(_.VERSION_KEY, versionUpdate.frontendVersion);
            await this.toast.presentBasicToast({ icon: "cloud-done-outline", header: this.translate.instant("APP.SOFTWARE_UPDATE.NEW_VERSION"), message: this.translate.instant("APP.SOFTWARE_UPDATE.APP_UPDATED_TO_VERSION", { appVersion: versionUpdate.frontendVersion }), color: 'tertiary' });
          }

        } catch (ex) {
          console.log("Error during check min supported version", ex);
        }
      })
    ).subscribe()

    this.SIS.connectedNamespaces.get(WsNamespaces.Client).subscribe((connected) => {
      if (connected)
        this.SIS.sendMessage<IClientSocket.Dto.HandshakeData>(
          WsNamespaces.Client,
          IClientSocket.ClientEvents.Handshake,
          { clientId: this.mainStateService.getClientId() }
        );
    });

    this.$login.subscribe(
      {
        next: async () => {
          let browserLang = this.translate.getBrowserLang() as Language;
          const { lang } = this.mainStateService.getUser(); 
          await this.userService.updateMe({ lang: { frontend: browserLang } });
        }
      }
    )
  }

  // questo metodo va chiamato solo dall'ngOnInit dell'app.component (una sola volta ovviamente)
  // successivamente tutte le propietà calcolate possono essere ustate/valutate solo successivamente all'inizializzazione
  async init() {
    try {
      console.log("inizializzazione ...");

      const clientId = this.mainStateService.getClientId();
      const native = this.mainStateService.getNative();

      const rv = await this.platform.ready();
      const platforms = this.platform.platforms();
      const agent = navigator.userAgent;

      console.log("CLIENT:", clientId, "PLATFORM READY:", rv, "PLATFORM:", platforms);
      console.log("agent", agent);

      this.checkNotNativePlatform(platforms, agent);

      if (native) {
        await this.appNativeService.init();
      }
      else {
        try {
          const pos = await this.geolocation.getCurrentPosition(({
            enableHighAccuracy: true,
          }));
          this._hasGeoloc = !!pos?.timestamp;

        } catch (err) {
          console.error("Errore inizializzazione localizzazione app non nativa", err);
        }
      }
      await this.setQrCodeUrlParsers();
    } catch (err) {
      console.error("Errore inizializzazione", err);
    }
    console.log("inizializzazione terminata");
    this.mainStateService.setInitializationCompleted(true);


    // Ogni minuto (se la localizzazione è attiva) mi segno la positione del client, in modo da averla già pronta qualora dovesse servire per avviare una sessione
    // Se lo faccio all'occorrenza, infatti, posso avere un ritardo elevato dovuto al tempo necessario per ottenere la posizione
    let self = this;
    setInterval(async () => {
      if (this.isLocationEnabled()) {
        await self.saveCurrentPosition();
      }
    }, 30000)
  }

  get isProduction(): boolean {
    return environment.production;
  }

  checkNotNativePlatform(platforms: string[], agent: string) {
    let isIos = false;
    let isAndroid = false;

    try {
      isIos = !!platforms ? platforms.includes('ios') : agent.indexOf('iPhone') > 0;
      isAndroid = !!platforms ? platforms.includes('android') : agent.indexOf('Android') > 0;
    } catch (err) { }

    if (isIos) {
      if (!this.mainStateService.getNative()) {
        this.mainStateService.setPlatformType(PlatformType.iOSMobileWeb);
      }
    } else if (isAndroid) {
      if (!this.mainStateService.getNative()) {
        this.mainStateService.setPlatformType(PlatformType.AndroidMobileWeb);
      }
    } else {
      this.mainStateService.setPlatformType(PlatformType.Desktop);
    }
  }

  showWaitingToast(message?: string, duration?: number) {
    this.waitingToastData.next({
      isProgression: true,
      message: message ? message : 'Attendere il completamento dell\'operazione.',
      duration: duration ? duration : 120
    });
  }

  hideWaitingToast() {
    this.waitingToastData.next({ isProgression: false });
  }

  async showAppInfo(chapterId?: string, source?: ModalSource) {
    if (this.modalCtrl && source !== ModalSource.Registration) this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({ component: AppInfoComponent, cssClass: 'yf-master-detail-modal', componentProps: { selectedChapterId: chapterId ? chapterId : undefined } });
    await modal.present();
    await this.router.navigate([], {
      queryParams: { open_app_info: null },
      queryParamsHandling: 'merge',
    });
  }

  async showSingleChapter(chapterId?: string, saveToLocalSotrage: boolean = false, logoutOnDenial = true) {
    if (this.modalCtrl) this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create(
      {
        component: ModalChapterUpdatedComponent,
        backdropDismiss: false,
        componentProps: { selectedChapterId: chapterId ? chapterId : undefined, saveToLocalSotrage, logoutOnDenial }
      },);
    await modal.present();
  }

  notEmpty(obj: any): boolean {
    if (obj === null || obj === undefined) {
      return false;
    }
    if (Array.isArray(obj)) {
      return obj.length > 0;
    }
    if (typeof obj === 'object') {
      return Object.values(obj).some(value => this.notEmpty(value));
    }
    return obj !== null;
  }

  private async saveCurrentPosition() {
    const coord = await this.getCurrentPosition();
    this.mainStateService.setClientLastPosition(coord);
  }

  async getCurrentPosition(): Promise<Point> {
    let coord: Point = {
      type: 'Point',
      coordinates: [0, 0]
    };

    try {
      let geopos: Geoposition;
      if (this.mainStateService.getNative()) {
        geopos = await this.geoLocationNative.getCurrentPosition();
      } else {
        geopos = await this.geolocation.getCurrentPosition();
      }

      if (geopos) {
        coord = { ...coord, coordinates: [geopos.coords.longitude, geopos.coords.latitude] }
      }
    } catch (ex) {
      console.log(ex);
    }
    return coord;
  }

  isLocationEnabled(): boolean {
    if (this.mainStateService.getNative()) {
      return this.geoLocationNative.hasGeoloc;
    }
    else {
      return this.hasGeoloc;
    }
  }

  async setStartingFilterState() {
    let plugs = DEFAULT_FILTER.plugs;
    let range = DEFAULT_FILTER.range;
    let cpos = DEFAULT_FILTER.cpos;
    let savedFilter = this.store.get<CarFilters>(_.SELECTED_FILTER);
    if (savedFilter) {
      plugs = savedFilter.plugs || DEFAULT_FILTER.plugs;
      range = savedFilter.range || DEFAULT_FILTER.range;
      cpos = savedFilter.cpos || DEFAULT_FILTER.cpos;
    }
    let filter: CarFilters = { plugs, range, quick: [], carId: null, cpos };
    this.mainStateService.setFilterButtonState(filter);
  }

  // async setQrCodeUrlParsers() {
  //   const firstFunctionTest = await this.HIO.get<string[]>('test-url-parser');
  //   if (firstFunctionTest) {
  //     const enelxQrCodeFunction = eval(firstFunctionTest[0]);
  //     const res = enelxQrCodeFunction("https://smart.enelxway.it/?id=21XP22T3KK4AK170RJ*1");
  //     console.log("First function test result", res);
  //     this.qrCodePatternFunctions = firstFunctionTest;
  //   }
  //   console.log("First function test", firstFunctionTest);
  // }

  // checkQrCodeKnownPatterns(value: string) {
  //   console.log("Checking qr code known patterns", value);
  //   for (const f of this.qrCodePatternFunctions) {
  //     const fn = eval(f);
  //     const res = fn(value);
  //     if (res) {
  //       console.log("Result test from qrcode component", res);
  //       return res;
  //     }
  //   }
  // }
  async setQrCodeUrlParsers() {
    const urlParsers = await this.HIO.get<string[]>('test-url-parser');
    if (urlParsers) {
      const enelxQrCodeFunction = new Function('return ' + urlParsers[0])();
      this.qrCodePatternFunctions = urlParsers.map(
        fnStr => new Function('return ' + fnStr)()
      );
    }
    const textParsers = await this.HIO.get<string[]>("test-string-qrcode");
    if (textParsers) {
      this.qrCodeTextPatternFunctions = textParsers.map(
        fnStr => new Function('return ' + fnStr)()
      );
    }
  }

  checkQrCodeKnownPatterns(value: string) {
    console.log("REQ", value);
    for (const fnc of this.qrCodePatternFunctions) {
      const res = fnc(value);
      if (res) {
        console.log("RES", res)
        return res;
      }
    }
  }

  checkQrCodeTextKnownPatterns(value: string): {
    stationId: string,
    connectorId: number
  } {
    for (const fnc of this.qrCodeTextPatternFunctions){
      const res = fnc(value);
      console.log("Res TEXTKNOWN PATTERNS", res);
      if (res) {
        return res;
      }
    }
  }

}
