<app-splash-screen></app-splash-screen>
<app-update-screen></app-update-screen>

<ion-app>
  @if($native){
  <div class="app-navbar-notch"></div>
  }
  <ion-content scrollY="false" id="main-content">

    <app-map [center]="mapCenter" [data]="data" [updates]="updates" [reload]="mapReload"
      [selectedInstallation]="mainStateService.$selectedInstallation()" (clickItem)="onMapClicked($event)"
      (mapBounds)="onMapBoundsChanged($event)">
    </app-map>

    @if(!mainStateService.$searchPanelVisible()){
    <div class="fab-container-left">
      <div class="left-side">
        <ion-fab>
          <ion-fab-button (click)="showSearch()" color="light">
            <ion-icon name="search"></ion-icon>
          </ion-fab-button>
        </ion-fab>
      </div>
    </div>

    <div class="fab-container-right">
      <ion-fab class="funnel-fab">
        <ion-fab-button (click)="presentFilter()" [color]="$filterButtonColor()">
          @if(!$showFilters()){
          <ion-icon name="funnel-outline"></ion-icon>
          }
          @else{
          <ion-icon name="close-outline" size="large"></ion-icon>
          }
        </ion-fab-button>
      </ion-fab>
    </div>
    }

    @if($showFilters()){
    <yf-filter-modal [@inOutAnimation] (filterChange)="onMapBoundsChanged($event)"></yf-filter-modal>
    }

    <yf-search-panel (showPosition)="this.searchResult($event)"></yf-search-panel>

    @if($showSessionToast()){
    <yf-session-toast (componentEvent)="sessionToastEvent($event)">
    </yf-session-toast>
    }

    @if(mainStateService.$selectedInstallation()){
    <yf-installation [currentPosition]="this.currentPosition" (openLogin)="presentLogin({ type: 'login' })"
      (onSelectPrev)="selectPrev($event)" (onSelectNext)="selectNext($event)">
    </yf-installation>
    }

    <yf-cluster></yf-cluster>

    <yf-app-status-leds></yf-app-status-leds>
    @if(!mainStateService.$selectedInstallation() && $native){
    <div class="fab-container-bottom-left">
      <div class="left-side">
        <ion-fab>
          <ion-fab-button color="light">
            <ion-icon name="scan-circle-outline"></ion-icon>
          </ion-fab-button>
          <ion-fab-list side="top">
            <ion-fab-button (click)="openQrCodeScanner()">
              <ion-icon name="qr-code-outline" ></ion-icon>
            </ion-fab-button>
            <ion-fab-button (click)="openOpticalCharacterRecognition()">
              <ion-icon name="remove-outline"></ion-icon>
              <ion-icon name="scan-outline"></ion-icon>
            </ion-fab-button>
          </ion-fab-list>
        </ion-fab>
      </div>
    </div>
  }
  </ion-content>

  <ion-footer>
    @if(!!(user$ | async)){
    <ion-toolbar color="secondary">
      <ion-buttons>
        <div slot="start">
          <ion-button>
            <ion-menu-toggle>
              <ion-icon name="ellipsis-vertical-outline"></ion-icon>
            </ion-menu-toggle>
          </ion-button>
        </div>
        <div class="footer-button-container" slot="center">
          <ion-button (click)="showNotificationModal()">
            <!-- <ion-icon src="./../assets/app-icons/notification.svg"></ion-icon> -->
            <ion-icon name="notifications-outline"></ion-icon>
          </ion-button>

          <ion-button (click)="presentUserSessions()">
            <ion-icon src="./../assets/app-icons/history.svg"></ion-icon>
          </ion-button>
        </div>
        <div slot="end">
          <ion-button (click)="_showAppInfo()"><ion-icon slot="icon-only" name="information-circle-outline"
              color="light"></ion-icon></ion-button>
        </div>
      </ion-buttons>

    </ion-toolbar>
    }
    @else {
    <ion-toolbar color="primary">
      <ion-buttons slot="secondary">
        <ion-button (click)="presentLogin({ type: 'login' })"><ion-icon slot="start" name="enter-outline"></ion-icon>{{
          "LOGIN.LOGIN" | translate }}</ion-button>
      </ion-buttons>
      <ion-buttons slot="primary">
        <ion-button (click)="_showAppInfo()"><ion-icon slot="icon-only"
            name="information-circle-outline"></ion-icon></ion-button>
      </ion-buttons>
    </ion-toolbar>
    }

  </ion-footer>

  <yf-main-menu (itemClicked)="itemClicked($event)"></yf-main-menu>

</ion-app>
