import { trigger, style, transition, animate } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit, ViewChild, Inject, ChangeDetectionStrategy, NgZone, computed, effect, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { IonFab, MenuController, ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { ActionResponse, ActionType, ClientEvents, IClientSocket, IMapBounds, ISession, WsNamespaces, IUser, CarFilters, IUpdateUserDTO, IInstallation, ActionTypeValues, PlugType, ChapterVisualizationPhase, IInfoChapter, Language, IRfid } from 'common_library';
import { interval, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { APP_MAIN_RX_STATE, GLOBAL_RX_STATE, GlobalState } from 'src/app/app.module'
import { RxState } from '@rx-angular/state';
import { IMapSettings, MapItem, MapItemType, MapPosition, InstallationProperties } from './components/map/map.component';
import { _ } from './consts';
import { LoginPage } from './pages/login/login.page';
import { ISessionToastEvent, SessionToastEventType } from './pages/session-toast/session-toast.component';
import { UserProfilePage } from './pages/user-profile/user-profile.page';
import { AppService } from './services/app.service';
import { AuthService } from './services/auth/auth.service';
import { SocketIoService } from './services/communication/socket-io.service';
import { CoreService } from './services/core.service';
import { AlertService } from './services/utils/alert.service';
import { NavigateToPointService } from './services/utils/navigate-to-point.service';
import { ToastService } from './services/utils/toast.service';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { CdcAuthComponent } from './components/master-detail-cdc-authorization/cdc-auth.component';
import { RfidDetailsComponent } from './components/master-details-rfid/rfid-details.component';
import { NotificationDetailsComponent } from './components/master-details-notification/notification-details.component';
import { AppInfoComponent } from './components/master-details-app-info/app-info.component';
import { NotificationService } from './services/entities/notification.service';
import { UserProfileComponent } from './components/master-detail-user-profile/user-profile.component';
import { StoreChapter, StoreService } from './services/utils/store.service';
import { HttpIoService } from 'src/app/services/communication/http-io.service';
import { GeoLocationNativeService } from './services/native/geo-location-native.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { FirebaseAuthenticationService } from './services/not-native/firebase-authentication.service';
import { SessionsComponent } from './components/master-detail-sessions/sessions.component';
import { InfoService } from './services/entities/info.service';
import { UserService } from './services/entities/user.service';
import { SelectedInstallationService } from './services/entities/selected-installation.service';
import { TranslateConfigService } from './services/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { AppMainState } from './types/state/app-main-state.interface';
import { MainStateService } from './services/state/app-main-state.service';
import { SessionService } from './services/entities/session.service';
import { SelectedClusterService } from './services/entities/selected-cluster.service';
import { CarService } from './services/entities/car.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-40px)', opacity: 0 }),
        animate('200ms ease-in', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('200ms ease-out', style({ transform: 'translateY(-40px)', opacity: 0 })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  readonly mainState$ = this.globalState.select("mainState");

  user$: Observable<IUser>;
  showSplashScreen$: Observable<boolean>;
  showOfflineScreen$: Observable<boolean>;
  mapResize$: Observable<boolean>;
  $native = this.mainStateService.$native();

  @ViewChild('fabUser') fabUser: IonFab;
  data: GeoJSON.FeatureCollection<GeoJSON.Point> = { type: 'FeatureCollection', features: [] };
  updates: InstallationProperties[] = [];
  mapSettings: IMapSettings;
  mapCenter: MapPosition;
  mapZoom: number;
  currentPosition: MapPosition;
  showSessionToast: boolean;
  $showFilters = signal<boolean>(false);
  mapReload = false;
  showOfflineToast: boolean;
  mapCenterInitialized: boolean;
  mapLoaded$ = this.globalState.select('mapLoaded');
  mapInstallation$: Observable<IInstallation>;
  private updatesToast: HTMLIonToastElement;
  $filterAreNotActive = computed(()=>{
    const filterButtons = this.mainStateService.$filterButtonState();
    return filterButtons?.plugs?.length === Object.keys(PlugType).length && filterButtons?.range?.lower === 2 && filterButtons?.range?.upper === 350
  })

  currentMapBounds: IMapBounds;

  constructor(
    @Inject(GLOBAL_RX_STATE) private globalState: RxState<GlobalState>,
    @Inject(APP_MAIN_RX_STATE) private appMainState: RxState<AppMainState>,
    private HIO: HttpIoService,
    public modalCtrl: ModalController,
    private alert: AlertService,
    public auth: AuthService,
    private toast: ToastService,
    private activatedRoute: ActivatedRoute,
    public SIS: SocketIoService,
    private router: Router,
    private swUpdate: SwUpdate,
    private appService: AppService,
    public navService: NavigateToPointService,
    public CS: CoreService,
    public geolocation: Geolocation,
    private notificationService: NotificationService,
    private menuCtrl: MenuController,
    private storage: StoreService,
    private zone: NgZone,
    private firebaseAuth: FirebaseAuthenticationService,
    private cdr: ChangeDetectorRef,
    private infoService: InfoService,
    private userService: UserService,
    private carService: CarService,
    public mainStateService: MainStateService,
    private installationService: SelectedInstallationService,
    private clusterService: SelectedClusterService,
    private sessionService: SessionService,
    private translateConfigService: TranslateConfigService,
    private translate: TranslateService
  ) {

    this.initializeFirebase()
    this.user$ = this.mainStateService.user$;
    effect(async ()=> {
      if(this.mainStateService.$filterButtonState) {
        await this.reloadInstallationsOnMap(null);
      }
    })
    this.translateConfigService.getDefaultLanguage();
    this.appService.setStartingFilterState();
    this.SIS.fromEvent<InstallationProperties[]>(
      WsNamespaces.Client,
      IClientSocket.ServerEvents.InstallationsUpdates
    ).subscribe((su) => {
      this.updates = su;
      console.log("App component -> Update SIS Installations");
      console.log(this.updates);
    });

    this.SIS.fromEvent<ISession>(
      WsNamespaces.Client,
      IClientSocket.ServerEvents.SessionUpdates
    ).subscribe((s: ISession) => {
      if (!!s) {
        console.log("App component -> SessionUpdates", s);
        this.mainStateService.updateSessionsWithCurrent(s);
      }
    });

    this.SIS.fromEvent<IRfid[]>(WsNamespaces.Client, IClientSocket.ServerEvents.RfidUpdates).subscribe((r: IRfid[]) => {
      this.mainStateService.setUserRfids(r);
    });

    this.mainStateService.initializationCompleted$.pipe(
      filter(c => !!c))
      .subscribe(async () => {
        console.log('App inizializzata ...');

        if (!this.appService.isLocationEnabled()) {
          this.toast.presentBasicToast({
            header: this.translate.instant("APP.LOCALIZATION.ENABLE_LOCALIZATION"),
            message: this.translate.instant("APP.LOCALIZATION.ENABLE_LOCALIZATION_INFO"),
            color: 'secondary',
            duration: 6000,
            icon: 'location-outline'
          });
        }
        /*
                const native = this.mainStateService.getNative();
                if (native) {
                  if (!this.geoLocationNativeService.hasGeoloc) {
                    this.toast.presentBasicToast({
                      header: this.translate.instant("APP.LOCALIZATION.ENABLE_LOCALIZATION"),
                      message: this.translate.instant("APP.LOCALIZATION.ENABLE_LOCALIZATION_INFO"),
                      color: 'secondary',
                      duration: 6000,
                      icon: 'location-outline'
                    });
                  }
                }
                else if (!this.appService.hasGeoloc) {
                  this.toast.presentBasicToast({
                    header: this.translate.instant("APP.LOCALIZATION.ENABLE_LOCALIZATION"),
                    message: this.translate.instant("APP.LOCALIZATION.ENABLE_LOCALIZATION_INFO"),
                    color: 'secondary',
                    duration: 6000,
                    icon: 'location-outline'
                  });
                  await this.tutorialGeoService.presentModalTutorial(
                    'generic-geolocation',
                    this.translate.instant("APP.LOCALIZATION.ALLOW_LOCALIZATION"),
                    this.translate.instant("APP.LOCALIZATION.ENABLE_LOCALIZATION_FOLLOW_INSTRUCTIONS"),
                    2,
                    ['provaA', 'provaB', 'provaC', 'provaD']
                  );
        //window.location.reload();
        //}
      }*/

        // Mostro i contenuti che devono essere visualizzati all'avvio dell'applicazione
        await this.infoService.getCmsContent();
        let chaptersToShow = await this.userService.getChaptersUserMustAcceptForVisualizationPhase(ChapterVisualizationPhase.APP_START);// await this.userService.getChaptersUserMustVisualizeAtStartup();
        if (chaptersToShow && chaptersToShow.length > 0) {
          let chapters = this.storage.get<StoreChapter[]>(_.CHAPTERS_KEY);
          if (chapters && chapters.length > 0) {
            let unreadChapter: IInfoChapter = null;
            let index = 0;
            while (!unreadChapter && index < chaptersToShow.length) {
              let currentChapterToShow = chaptersToShow[index];
              let currentChapterComposition = this.infoService.populateChapterComposition(currentChapterToShow);
              let chapterReaded = chapters.find(c => c.chapterid === currentChapterToShow.id && c.chapterComposition === currentChapterComposition);
              if (!chapterReaded) {
                unreadChapter = currentChapterToShow;
              }
              index++;
            }
            if (unreadChapter) {
              this.appService.showSingleChapter(unreadChapter.id, true, unreadChapter.userAccepted);
            }
          }
          else {
            this.appService.showSingleChapter(chaptersToShow[0].id, true, chaptersToShow[0].userAccepted);
          }
        }
      });

    // Quando l'utente si logga, aggiorno le sessioni, le installazioni (potrebbe avere prezzi custom) e le auto
    effect(async () => {
      let user = this.mainStateService.$user();
      if (user) {
        const activeSessions = await this.sessionService.fetchCurrentSessions();
        this.mainStateService.setSessions(activeSessions);
        await this.reloadInstallationsOnMap(null);
        this.mainStateService.setSelectedInstallation(null);
        await this.carService.fetchCars();
        await this.userService.fetchRfids();
      } else {
        this.mainStateService.setSessions(null);
        this.mainStateService.setSelectedInstallation(null);
        this.mainStateService.setUserCars([]);
        this.mainStateService.setUserRfids([]);
      }
    }, { allowSignalWrites: true });


    this.user$.subscribe(async (user) => {
      if (user) {
        let userAutomaticallyUpdated = false;
        // Completamento nome cognome obbligatorio
        // Se l'utente fa login social ma non ho i dati su DB, salvo quelli che vengono da social, se ci sono
        // se non ci sono nemmeno da social, li chiedo tramite form
        if (user.social) {
          let userFromDb = await this.userService.getMe();
          if (((!userFromDb.name ?? '') || (!userFromDb.surname ?? '')) && ((user.name) || (user.surname))) {
            const dto: IUpdateUserDTO = {
              email: userFromDb.email,
              name: user.name.trim(),
              surname: user.surname.trim()
            };

            const userUpdated = await this.userService.updateMe(dto);
            if (userUpdated) {
              userAutomaticallyUpdated = true;
              this.mainStateService.setUser(userUpdated);
            }
          }
        }

        if (!userAutomaticallyUpdated) {
          // ogni volta che il valore di user$ cambia, devo ripescare il cmsContent,
          // perché la possibilità di vedere o meno un capitolo del contenuto dipende dal gruppo di appartenenza dell'utente corrente (se c'è un utente)
          // e dal gruppo di appartenenza dei capitoli che sono pubblicati
          await this.infoService.getCmsContent();
          let chaptersToShow = await this.userService.getChaptersUserMustAcceptForVisualizationPhase(ChapterVisualizationPhase.LOGIN);
          if (chaptersToShow && chaptersToShow.length > 0) {
            this.appService.showSingleChapter(chaptersToShow[0].id, false, chaptersToShow[0].userAccepted);
          }
        }
      }
    });
  }

  $showSessionToast = computed(() => {
    return this.mainStateService.$sessions()?.length > 0 && !(this.mainStateService.$selectedInstallation() || this.mainStateService.$selectedCluster());
  })

  async ngOnInit() {
    const native = this.mainStateService.getNative();
    await this.infoService.getCmsContent();

    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        distinctUntilChanged((prev, curr) => prev.latestVersion === curr.currentVersion),
        debounceTime(1000)
      )
      .subscribe(async (res) => {
        if (this.updatesToast) {
          await this.updatesToast.dismiss({}, 'destructive');
        }

        await this.toast.presentBasicToast({ icon: "cloud-download-outline", header: this.translate.instant("APP.SOFTWARE_UPDATE.TITLE"), message: this.translate.instant("APP.SOFTWARE_UPDATE.MESSAGE"), color: 'light' });
        await this.swUpdate.activateUpdate();
        window.location.reload();
      });

    const s = environment.production ? 30 : 5;

    interval(s * 1000).subscribe(async () => {
      try {
        await this.swUpdate.checkForUpdate();
      } catch (err) {
        console.log(err);
      }
    });

    if (native) {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(async () => {
          const domain = environment.url;
          const pathArray = event.url.split(domain);
          const appPath = pathArray.pop();

          //Controllo se ho un path
          if (appPath) {
            const urlParams = new URLSearchParams(appPath.split('?')[1]);
            const action_uid = urlParams.get('action_uid');
            if (action_uid) {
              await this.executeActionByUid(action_uid);
            }

            const notification_uid = urlParams.get('notification_uid');
            if (notification_uid) {
              await this.executeNotificationByUid(notification_uid);
            }

            const open_app_info = urlParams.get('open_app_info');
            if (open_app_info) {
              await this.appService.showAppInfo(open_app_info);
            }

            const installation_id = urlParams.get('installation_id');
            if (installation_id) {
              this.globalState.set({ installationByDeeplink: true });
              await this.presentInstallationInfo(installation_id);
              this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: null,
                replaceUrl: true
              });
            }
          }
        })
      })
    } else {
      this.activatedRoute.queryParams.subscribe(async (params) => {
        // ?action_uid=08ece4e7-1598-47cb-a2f0-3b73b6284870&a=GESPAY92168&Status=OK&paymentID=1417219232671&paymentToken=ba564046-bab9-46de-88bf-5c2c9817e5c3

        if (params.action_uid) {
          this.executeActionByUid(params.action_uid)
        }

        else if (params.notification_uid) {
          this.executeNotificationByUid(params.notification_uid);
        }

        else if (params.open_app_info) {
          this.appService.showAppInfo(params.open_app_info)
        }

        else if (params.installation_id) {
          this.globalState.set({ installationByDeeplink: true });
          await this.presentInstallationInfo(params.installation_id);
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: null,
            replaceUrl: true
          });
        }
      });
    }

    this.SIS.connected$
      .subscribe(async (connected) => {
        console.log('socket connected:', connected);
        if (connected && this.showOfflineToast) {
          this.mapReload = true;
        } else if (!connected && !this.showOfflineToast) {
          this.showOfflineToast = false;
          await this.mainStateService.setSelectedInstallation(null);
          this.data = { type: 'FeatureCollection', features: [] };
          this.mapReload = false;
        } else if (!connected) {
          //TENTATIVO DI CONNESSIONE?
        }
      });

    await this.appService.init();
  }

  showSearch() {
    this.$showFilters.set(false);
    this.mainStateService.setSearchPanelVisible(true);
  }

  // #region MAP events

  async onMapClicked(evt: MapItem) {
    this.mainStateService.setSearchPanelVisible(false);

    const cluster = this.appMainState.get("selectedCluster");
    const installation = this.appMainState.get("selectedInstallation");
    switch (evt.type) {
      case MapItemType.BACKGROUND:
        if (installation) {
          await this.hideInstallationInfo();
        } else if (cluster?.length > 0 && !installation) {
          await this.hideClusterInfo();
        }
        break;
      case MapItemType.CLUSTER:
        await this.hideInstallationInfo();

        let ids = evt.properties["ids"]?.slice(1, -1).split(",").map(id => id.slice(1, -1));
        if (!ids) {
          await this.toast.presentBasicToast({ icon: "warning-outline", header: this.translate.instant("APP.INSTALLATION_CLUSTER.ERROR_TITLE"), message: this.translate.instant("APP.INSTALLATION_CLUSTER.ERROR_MESSAGE"), color: 'light' });
          break;
        }
        await this.presentInstallationsList(ids);
        break;
      case MapItemType.INSTALLATION:
        await this.hideClusterInfo();
        await this.presentInstallationInfo((evt.properties as InstallationProperties).id);
        break;
    }
  }

  async onMapBoundsChanged(evt: IMapBounds) {
    console.log("onMapBoundsChanged", JSON.stringify(evt));
    await this.reloadInstallationsOnMap(evt);
  }

  private async reloadInstallationsOnMap(evt: IMapBounds) {
    // In questo momento (DEMO) viene fatto ad ogni mapBound, da valutare se si può salvare il dato dei gruppi dell'utnte da qualche parte
    let uvg = this.mainStateService.getUserVisibilityGroups();
    if (!uvg || (((new Date().getTime() - uvg.updatedAt.getTime()) / 1000) > _.USER_GROUPS_VISIBILITY_CACHE_SECONDS)) {
      console.log("USER_GROUPS_VISIBILITY_CACHE_SECONDS", _.USER_GROUPS_VISIBILITY_CACHE_SECONDS);
      // Se l'utente è loggato, provo a recuperarli, altrimenti niente
      if (this.mainStateService.getUser()) {
        await this.userService.getMe();
        uvg = this.mainStateService.getUserVisibilityGroups();
      }
    }
    console.log("UVG", uvg);
    //
    let filterModel;
    if (evt) {
      this.currentMapBounds = evt;
    }
    const savedFilters = this.mainStateService.getFilterButtonState();
    if (!savedFilters) {
      filterModel = savedFilters;
    } else {
      filterModel = { quick: [], carId: null, plugs: [...Object.values(PlugType)], range: { lower: 2, upper: 350 } };
      this.storage.set(_.SELECTED_FILTER, filterModel);
    }
    this.data = await this.SIS.sendRequest<{ mapBounds: IMapBounds; filters?: CarFilters; userVisibilityGroups?: number[]; }, GeoJSON.FeatureCollection<GeoJSON.Point>>(
      WsNamespaces.Client,
      ClientEvents.CurrentBounds,
      {
        mapBounds: this.currentMapBounds,
        filters: filterModel ? filterModel : undefined,
        userVisibilityGroups: uvg?.groups ? uvg.groups : undefined
      }
    );
    this.cdr.detectChanges();
  }

  // #endregion MAP events

  private async initializeFirebase(): Promise<void> {
    await this.firebaseAuth.initialize();
  }

  async executeActionByUid(actionUid: string) {
    try {
      const { type } = await this.HIO.get<{ type: ActionTypeValues }>(`actions/get-type/${actionUid}`);
      let modal: HTMLIonModalElement | undefined;
      switch (type) {
        case ActionType.CdCAuthSuccess:
          modal = await this.modalCtrl.create({ component: CdcAuthComponent, cssClass: 'yf-master-detail-modal', componentProps: { waiting: true } });
          await modal.present();
          break;
      }

      const rv = await this.HIO.get<ActionResponse>(`actions/execute/${actionUid}`);

      if (modal) modal.dismiss();

      console.log('action_uid', actionUid, "response", rv);
      if (rv.data) {
        switch (rv.type) {
          case ActionType.RegisterNewUser:
            await this.auth.silentLogoutIfLogged();
            await this.presentLogin({ type: 'login', email: rv.data.email });
            break;
          case ActionType.ForgotPassword:
            await this.auth.silentLogoutIfLogged();
            await this.presentLogin({ type: 'changePw', email: rv.data.email });
            await this.presentLogin({ type: 'login', email: rv.data.email });
            break;
          case ActionType.ChangedPassword:
            await this.auth.silentLogoutIfLogged();
            await this.presentLogin({ type: 'login', email: rv.data.email });
            break;
          case ActionType.CdCAuthFailed:
            await this.creditCardDataPresent({ type: 'failed', data: rv.data });
            // this.globalState.set({cdcComponentState: {currentDetail: 0, authCharge: { type: 'failed', data: null }}})
            break;
          case ActionType.CdCAuthSuccess:
            await this.creditCardDataPresent({ type: 'success', data: rv.data });
            // this.globalState.set({cdcComponentState: {currentDetail: 2, authCharge: { type: 'success', data: null }}})
            break;
        }
      }

      // Remove query params
      this.router.navigate([], {
        queryParams: { action_uid: null },
        queryParamsHandling: 'merge',
      });

      if (rv.err) {
        await this.toast.presentBasicToast({ icon: "warning", header: this.translate.instant("LOGIN.REGISTRATION_ERROR"), message: rv.err, color: 'danger' });
      }
    } catch (err) {
      console.log('🐱️ : err', err);
    }
  }

  async executeNotificationByUid(notification_uid: string) {
    console.log('Apertura tramite notifica', notification_uid)
    const dto = { clientId: this.mainStateService.getClientId() }
    this.notificationService.update(notification_uid, dto)
  }

  async presentLogin<T>(componentProps: { [key: string]: any }): Promise<OverlayEventDetail<T>> {
    const modal = await this.modalCtrl.create({
      component: LoginPage,
      componentProps,
      cssClass: 'login-modal',
    });

    await modal.present();
    const rv = await modal.onWillDismiss<T>();
    return rv;
  }

  async presentUserProfile() {
    const modal = await this.modalCtrl.create({
      component: UserProfilePage,
      cssClass: 'yf-modal',
    });
    await modal.present();
  }

  async presentUserProfile2() {
    const modal = await this.modalCtrl.create({
      component: UserProfileComponent,
      cssClass: 'yf-master-detail-modal',
    });
    await modal.present();
  }

  async presentUserSessions() {

    const modal = await this.modalCtrl.create({
      component: SessionsComponent,
      cssClass: 'yf-master-detail-modal',
    });
    await modal.present();
  }

  async sessionToastEvent(evt: ISessionToastEvent) {
    switch (evt.type) {
      case SessionToastEventType.NavigateTo:
        this.navService.navigateTo(evt.source.connector.station.installation.coord.coordinates);
        break;
      case SessionToastEventType.OpenDetails:
        const rv = await this.installationService.fetchInstallation(evt.source.connector.station.installation.id, "Session toast event");
        this.mainStateService.setSelectedInstallation(rv);
        break;
      case SessionToastEventType.ShowOnMap:
        this.setMapCenter(evt.source.connector.station.installation.coord.coordinates[0], evt.source.connector.station.installation.coord.coordinates[1]);
    }
  }

  setMapCenter(lng: number, lat: number) {
    this.mapCenter = { lng, lat, showLocationMarker: false };
  }

  searchResult(evt) {
    this.mapCenter = { lng: evt.lng, lat: evt.lat, showLocationMarker: true };
  }

  async presentInstallationInfo(installationId: string) {
    // 2-- Seleziono l'installazione
    const rv = await this.installationService.fetchInstallation(installationId, "Present installation info, app.component.ts");
    this.mainStateService.setSelectedInstallation(rv);
  }

  async presentInstallationsList(ids: string[]) {
    await this.clusterService.selectCluster(ids);
  }

  async hideInstallationInfo() {
    this.mainStateService.setSelectedInstallation(null);
  }

  async hideClusterInfo() {
    await this.mainStateService.setSelectedCluster(null);
  }

  async presentFilter() {
    this.$showFilters.set(!this.$showFilters());
  }

  async logout() {
    const rv = await this.alert.confirmAlert("Logout", this.translate.instant("LOGOUT.WANT_TO_GET_OUT"), "primary", this.translate.instant("LOGOUT.EXIT"), this.translate.instant("BUTTONS.CANCEL"));
    if (rv) {
      await this.auth.logout();
      // Elimino tutti i dati relativi all'utente loggato
      await this.storage.clear();
      this.mainStateService.setUser(null);
      this.mainStateService.setUserVisibilityGroups(null);
      await this.updateCurrentMap();
    }
  }

  navigateTo(e) {
    this.navService.navigateTo(e);
  }

  private async updateCurrentMap() {
    if (this.currentMapBounds) {
      await this.onMapBoundsChanged(this.currentMapBounds);
    }
  }

  async selectNext(id: string) {
    await this.selectFromArray(id, 1);
  }

  async selectPrev(id: string) {
    await this.selectFromArray(id, -1);
  }

  async selectFromArray(id, delta) {
    const array = [];
    for (const f of this.data.features) {
      if (f.properties.id) array.push(f.properties.id);
      if (f.properties.ids) array.push(...f.properties.ids);
    }
    const idx = array.indexOf(id);
    const newIdx = idx + delta < 0 ? idx + delta + array.length : idx + delta;
    const selected = array[newIdx % array.length];
    await this.mainStateService.setSelectedInstallation(selected);
  }

  async creditCardDataPresent(resValidationCard: { type: 'success' | 'failed', data: any } = null) {
    const modal = await this.modalCtrl.create({
      component: CdcAuthComponent,
      cssClass: 'yf-master-detail-modal',
      componentProps: { resValidationCard }
    });
    await modal.present();
  }

  async showRfidModal(params: { type: 'success' | 'failed', data: any } = null) {
    const modal = await this.modalCtrl.create({
      component: RfidDetailsComponent,
      cssClass: 'yf-master-detail-modal',
      componentProps: { station: null }
    });
    await modal.present();
  }

  async showNotificationModal(params: { type: 'success' | 'failed', data: any } = null) {
    const modal = await this.modalCtrl.create({
      component: NotificationDetailsComponent,
      cssClass: 'yf-master-detail-modal',
      componentProps: { resValidationCard: params }
    });
    await modal.present();
  }

  async showAppInfo(chapterId?: string) {
    const modal = await this.modalCtrl.create({ component: AppInfoComponent, cssClass: 'yf-master-detail-modal', componentProps: { selectedChapterId: chapterId ? chapterId : undefined } });
    await modal.present();
    this.router.navigate([], {
      queryParams: { open_app_info: null },
      queryParamsHandling: 'merge',
    });
  }

  async itemClicked(item) {
    let modal: HTMLIonModalElement;
    if (item) {
      if (item.needsToBeLogged === false) {
        this.menuCtrl.close();
        this.presentLogin({ type: 'login' });
      } else if (!item.component) {
        this.logout();
      } else {
        modal = await this.modalCtrl.create({
          component: item.component,
          cssClass: item.cssClass,
        });
        await modal.present();
      }
    }
  }

  async _showAppInfo() {
    await this.appService.showAppInfo();
  }
}
