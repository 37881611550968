import { Component, Inject, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { ConfirmPasswordModel, IError, IOnlyEmailDTO, IRegisterDTO, IUser, Language, LoginModel } from 'common_library';
import { MyFormlyHelpers } from 'src/app/my-formly/my-formly.helpers';
import { AuthService } from '../../services/auth/auth.service';
import { AlertService } from '../../services/utils/alert.service';
import { ToastService } from '../../services/utils/toast.service';
import { GLOBAL_RX_STATE, GlobalState } from 'src/app/app.module';
import { RxState } from '@rx-angular/state';
import { ModalCompleteNameComponent } from 'src/app/components/modal-complete-name/modal-complete-name.component';
import { TranslateService } from '@ngx-translate/core';
import { _ } from 'src/app/consts';
import { TranslateConfigService } from 'src/app/services/translate.service';
import { UserService } from 'src/app/services/entities/user.service';

export enum FormType {
  Login = 'login',
  Register = 'register',
  ForgotPw = 'forgetPw',
  ChangePw = 'changePw',
}

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
    standalone: false
})
export class LoginPage implements OnInit {
  readonly FormType = FormType;

  @Input() type: FormType;
  @Input() email: string;

  loginFrm: MyFormlyHelpers.FormlyForm<LoginModel>;
  forgotPwFrm: MyFormlyHelpers.FormlyForm<IOnlyEmailDTO>;
  registerFrm: MyFormlyHelpers.FormlyForm<IRegisterDTO>;
  newPasswordFrm: MyFormlyHelpers.FormlyForm<ConfirmPasswordModel>;

  constructor(
    @Inject(GLOBAL_RX_STATE) private globalState: RxState<GlobalState>,
    public modalCtrl: ModalController,
    private alert: AlertService,
    private auth: AuthService,
    private toast: ToastService,
    private loadingController: LoadingController,
    private translate: TranslateService,
    private translateConfigService: TranslateConfigService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.loginFrm = new MyFormlyHelpers.FormlyForm<LoginModel>({ email: this.email ?? '', password: '' }, [
      MyFormlyHelpers.emailField(),
      MyFormlyHelpers.passwordField(false),
    ]);

    this.forgotPwFrm = new MyFormlyHelpers.FormlyForm<IOnlyEmailDTO>({ email: '' }, [
      MyFormlyHelpers.emailField(),
    ]);

    this.registerFrm = new MyFormlyHelpers.FormlyForm<IRegisterDTO>(
      { email: '', password: '', name: '', surname: '' },
      [
        MyFormlyHelpers.inputField('name', this.translate.instant("PROFILE.USER_DETAIL.NAME")),
        MyFormlyHelpers.inputField('surname', this.translate.instant("PROFILE.USER_DETAIL.SURNAME")),
        MyFormlyHelpers.emailField(),
        MyFormlyHelpers.confirmPasswordFields(true, this.translate.instant("BUTTONS.CONFIRM")),
      ]
    );

    this.registerFrm.form.valueChanges.subscribe((v) => {
      if (!!v) {
        this.globalState.set({ registrationForm: v });
      }
    });

    if (this.globalState.get("registrationForm")) {
      this.registerFrm.model = this.globalState.get("registrationForm") as any;
    }

    this.newPasswordFrm = new MyFormlyHelpers.FormlyForm<ConfirmPasswordModel>(
      { password: '', passwordConfirm: '' },
      [MyFormlyHelpers.confirmPasswordFields()]
    );
  }

  async closeMe() {
    await this.modalCtrl.dismiss();
  }

  changeForm = (type: FormType): void => {
    this.type = type;
  };

  async login() {
    if (!this.loginFrm.form.valid) {
      return;
    }
    try {
      let user = await this.auth.login(this.loginFrm.model.email, this.loginFrm.model.password);
      let browserLang = this.translate.getBrowserLang() as Language;
      if(user.lang.frontend !== browserLang) user = await this.userService.updateMe({ lang: {frontend: browserLang} });      
      this.closeMe();
      if (!!user) {
        await this.toast.presentBasicToast({ icon: "finger-print-outline", header: this.translate.instant("LOGIN.AUTHENTICATION"), message: this.translate.instant("LOGIN.LOGIN_SUCCESSFULL"), color: 'light' });
      }
      else
        await this.toast.presentBasicToast({ icon: "warning", header: this.translate.instant("LOGIN.AUTHENTICATION"), message: this.translate.instant("LOGIN.LOGIN_FAILED"), color: 'danger' });
    } catch (err) {
      this.toast.presentBasicToast({ icon: "bug-outline", header: this.translate.instant("TOAST_MESSAGES.ERROR"), message: err.message, color: 'danger' });
    }
  }

  async register() {
    if (!this.registerFrm.form.valid) {
      return;
    }

    try {
      const lang = this.translateConfigService.forceGetCurrentLanguage();
      const res = await this.auth.register(
        this.registerFrm.model.email,
        this.registerFrm.model.password,
        this.registerFrm.model.name,
        this.registerFrm.model.surname,
        lang
      );

      if (typeof res === "boolean") {
        if (res) {
          await this.alert.presentYfAlert({
            header: this.translate.instant("LOGIN.ACCOUNT_CREATED"),
            message: this.translate.instant("LOGIN.WE_HAVE_SENT_A_CONFIRMATION_EMAIL"),
            image: 'illustrazione_check_email.svg',
            buttons: ['Ok'],
          });
        } else {
          await this.toast.presentBasicToast({ icon: "warning", header: this.translate.instant("LOGIN.REGISTRATION_ERROR"), message: this.translate.instant("TOAST_MESSAGES.AN_ERROR_OCCURRED_CONTACT_SUPPORT"), color: 'danger' });
        }
      } else {
        await this.toast.presentBasicToast({ icon: "warning", header: this.translate.instant("LOGIN.REGISTRATION_ERROR"), message: res.message, color: 'danger' });
      }
    } catch (err) {
      this.toast.presentBasicToast({ message: err.message ? err.message : this.translate.instant("TOAST_MESSAGES.OOPS_SOMTHING_WENT_WRONG"), color: 'danger', icon: 'warning' });
    }
  }

  async forgotPassword() {
    if (!this.forgotPwFrm.form.valid) {
      return;
    }

    try {
      const res = await this.auth.forgotPassword(this.forgotPwFrm.model.email);
      if (res) {
        await this.alert.presentYfAlert({
          header: this.translate.instant("LOGIN.CHANGE_PASSWORD"),
          message: this.translate.instant("LOGIN.CHANGE_PASSWORD_INSTRUCTIONS"),
          image: 'illustrazione_check_email.svg',
          buttons: ['Ok'],
        });
      } else {
        await this.toast.presentBasicToast({ icon: "warning", header: this.translate.instant("LOGIN.CHANGE_PASSWORD"), message: this.translate.instant("LOGIN.THIS_EMAIL_IS_NOT_REGISTERED"), color: 'danger' });
      }
    } catch (err) {
      await this.toast.presentBasicToast({ message: err.message ? err.message : this.translate.instant("TOAST_MESSAGES.OOPS_SOMTHING_WENT_WRONG"), color: 'danger', icon: 'warning' });
    }
  }

  async changePassword() {
    if (!this.newPasswordFrm.form.valid) {
      return;
    }

    try {
      const res = await this.auth.changePassword(this.email, this.newPasswordFrm.model.password);
      if (res) {
        await this.toast.presentBasicToast({
          icon: "thumbs-up-outline",
          header: this.translate.instant("LOGIN.CHANGE_PASSWORD"),
          message: this.translate.instant("TOAST_MESSAGES.OPERATION_SUCCESSFULL"),
          color: 'secondary',
        });
        this.closeMe();
      }
    } catch (err) {
      await this.toast.presentBasicToast({ message: this.translate.instant("LOGIN.WE_COULDNT_UPDATE_PASSWORD_TRY_AGAIN_LATER"), color: 'danger', icon: 'warning' });
    }
  }

  async appleLogin(): Promise<void> {
    try {
      this.closeMe();
      const loadingController = await this.showLoadingIndictator();
      let user: IUser | IError = await this.auth.openAppleSignIn();

      let browserLang = this.translate.getBrowserLang() as Language;
      if (!('message' in user) && user.lang.frontend !== browserLang)  user = await this.userService.updateMe({ lang: {frontend: browserLang} });
      
      if (!user) return await this.toast.presentBasicToast({ icon: "warning", header: this.translate.instant("LOGIN.AUTHENTICATION"), message: this.translate.instant("LOGIN.LOGIN_FAILED"), color: 'danger' });
      if ('code' in user) {
        await this.toast.presentBasicToast({ icon: "warning", header: this.translate.instant("LOGIN.AUTHENTICATION"), message: `${user.message}`, color: 'danger' });
      } else if (!!user) {
        await this.toast.presentBasicToast({ icon: "finger-print-outline", header: this.translate.instant("LOGIN.AUTHENTICATION"), message: this.translate.instant("LOGIN.LOGIN_SUCCESSFULL"), color: 'light' });
      } else
        await this.toast.presentBasicToast({ icon: "warning", header: this.translate.instant("LOGIN.AUTHENTICATION"), message: this.translate.instant("LOGIN.LOGIN_FAILED"), color: 'danger' });
    } catch (err) {
      this.toast.presentBasicToast({ message: err.message, color: 'danger', icon: 'warning' });
    } finally {
      this.loadingController.dismiss();
    }
  }

  async googleLogin(): Promise<void> {
    try {
      this.closeMe();
      const loadingController = await this.showLoadingIndictator();
      let user: IUser | IError = await this.auth.openGoogleSignIn();

      let browserLang = this.translate.getBrowserLang() as Language;
      console.log("Browser Language google: ", browserLang)
      if (!('message' in user) && user.lang.frontend !== browserLang)  user = await this.userService.updateMe({ lang: {frontend: browserLang} });

      if (!user) return await this.toast.presentBasicToast({ icon: "warning", header: this.translate.instant("LOGIN.AUTHENTICATION"), message: this.translate.instant("LOGIN.LOGIN_FAILED"), color: 'danger' });
      if ('code' in user) {
        return await this.toast.presentBasicToast({ icon: "warning", header: this.translate.instant("LOGIN.AUTHENTICATION"), message: `${user.message}`, color: 'danger' });
      } else if (!!user) {
        await this.toast.presentBasicToast({ icon: "finger-print-outline", header: this.translate.instant("LOGIN.AUTHENTICATION"), message: this.translate.instant("LOGIN.LOGIN_SUCCESSFULL"), color: 'light' });
        if ((!user.name) || (!user.surname)) {
          if (this.modalCtrl) this.modalCtrl.dismiss();
          const modal = await this.modalCtrl.create({
            component: ModalCompleteNameComponent,
            backdropDismiss: false
          });
          await modal.present();
        }
      } else
        return await this.toast.presentBasicToast({ icon: "warning", header: this.translate.instant("LOGIN.AUTHENTICATION"), message: this.translate.instant("LOGIN.LOGIN_FAILED"), color: 'danger' });
    } catch (err) {
      this.toast.presentBasicToast({ message: err.message, color: 'danger', icon: 'warning' });
    } finally {
      this.loadingController.dismiss();
    }
  }

  private async showLoadingIndictator() {
    const loadingIndicator = await this.loadingController.create({
      message: this.translate.instant("LOGIN.OPEN_LOGIN_WINDOW"),
    });
    await loadingIndicator.present();
    return loadingIndicator;
  }
}
