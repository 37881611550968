import { Injectable } from '@angular/core';
import { IInfoChapter, IInfoContent, IRfid, IUpdateUserDTO, IUser, IUserChapter, ICreditCardDTO, IPaymentAuth, IPaymentAuthRedirect, IError, ChapterVisualizationPhaseValues, ChapterVisualizationPhase } from 'common_library';
import { HttpIoService } from '../communication/http-io.service';
import { ModalController } from '@ionic/angular';
import { StoreService } from '../utils/store.service';
import { ToastService } from '../utils/toast.service';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from '../state/app-main-state.service';
import { IUserVisibilityGroups } from 'src/app/types/state/app-main-state.interface';

const CONTROLLER_ROOT = 'user';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(
    private HIO: HttpIoService,
    private modalCtrl: ModalController,
    private storage: StoreService,
    private toast: ToastService,
    private mainStateService: MainStateService,
    private translate: TranslateService) { }

  async fetchRfids() {
    try {
      const rv: IRfid[] = await this.HIO.fetchItems<IRfid>(`rfid/user-rfids`);
      this.mainStateService.setUserRfids(rv);
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async getMe(): Promise<IUser> {
    try {
      const user = await this.HIO.fetchItem<IUser>(CONTROLLER_ROOT, 'me');
      this.updateUserVisibilityGroups(user?.visibilityGroups);
      return user;
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async updateMe(dto: IUpdateUserDTO): Promise<IUser> {
    try {
      return await this.HIO.patchItem<IUser, IUpdateUserDTO>(CONTROLLER_ROOT, 'me', dto);
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async deleteProfileConfirmed(user: IUser) {
    try {
      if (user.social) {
        await FirebaseAuthentication.deleteUser();
      }
      await this.delete(user.id);
      this.modalCtrl.dismiss();
      this.storage.clear();
      this.mainStateService.setUser(null);
      this.mainStateService.setUserVisibilityGroups(null);
      this.toast.presentBasicToast({
        header: this.translate.instant("PROFILE.USER_DETAIL.DELETING_PROFILE"),
        message: this.translate.instant("PROFILE.USER_DETAIL.PROFILE_SUCCESSFULLY_DELETED"),
        color: 'primary'
      })
    } catch (error) {
      console.error('🐱️ : error', error);
      this.toast.presentBasicToast({
        header: this.translate.instant("PROFILE.USER_DETAIL.DELETING_PROFILE"),
        message: this.translate.instant("PROFILE.USER_DETAIL.CANT_DELETE_PROFILE"),
        color: 'warning'
      })
    }
  }

  async delete(userId: string): Promise<IUser> {
    return await this.HIO.deleteItem(`${CONTROLLER_ROOT}/delete`, userId);
  }

  async addMeCreditCard(iCreditCardDTO: ICreditCardDTO): Promise<IPaymentAuthRedirect> {
    try {
      return await this.HIO.postItem<IPaymentAuthRedirect, ICreditCardDTO>('payment-core/me-cdc', iCreditCardDTO);
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async removeMyPaymentAuth(): Promise<boolean> {
    try {
      return await this.HIO.patchItem<boolean, void>('payment-auth', 'my', null);
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async getMyPaymentAuth(): Promise<Partial<IPaymentAuth>> {
    try {
      return await this.HIO.fetchItem<Partial<IPaymentAuth>>('payment-auth', 'my');
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async createUserChapterAcceptance(userId: string, chapterId: string): Promise<IUserChapter> {
    try {
      let dto: IUserChapter = {
        id: null,
        acceptedDate: null,
        chapterComposition: null,
        userId: userId,
        infoChapterId: chapterId
      };
      return await this.HIO.postItem<IUserChapter, IUserChapter>('user-chapter', dto);
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async checkUserChapterAcceptance(chapterId: string): Promise<boolean> {
    try {
      return await this.HIO.fetchItem<boolean>('user-chapter', `user-chapter-acceptance/${chapterId}`);
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async getChaptersUserMustAcceptForVisualizationPhase(visualizationPhase: ChapterVisualizationPhaseValues): Promise<IInfoChapter[]> {
    const chaptersMustAccept: IInfoChapter[] = [];
    const res: IInfoContent = this.mainStateService.getCmsContent();
    let infoChapters = res?.infoChapters.filter(c => c.visualizationPhase === visualizationPhase);
    for (let index = 0; index < infoChapters?.length; index++) {
      const element = infoChapters[index];
      if (visualizationPhase === ChapterVisualizationPhase.APP_START) {
        chaptersMustAccept.push(element);
      } else {
        const rv = await this.checkUserChapterAcceptance(element.id);
        if (!rv) {
          chaptersMustAccept.push(element);
        }
      }
    }
    return chaptersMustAccept;
  }

  async updateUserVisibilityGroups(visibilityGroups?: number[]) {
    const userVisibilityGroups: IUserVisibilityGroups = { groups: visibilityGroups, updatedAt: new Date() };
    this.mainStateService.setUserVisibilityGroups(userVisibilityGroups);
  }

  async payUnpaidCdr(cdrId: string): Promise<boolean | IError> {
    try {
      const rv = await this.HIO.postItem<boolean | IError, { cdrId: string }>(`bank-transaction/try-cdr-payment`, { cdrId });
      return rv;
    } catch (e) {
      return {
        code: 1,
        message: "Errore generale, riprova più tardi."
      }
    }
  }
}