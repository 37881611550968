import { Component, effect, Input, input, OnDestroy, OnInit, signal } from '@angular/core';
import { ISession, State } from 'common_library';
import { filter, map, shareReplay, Subscription, timer } from 'rxjs';
import { SessionService } from 'src/app/services/entities/session.service';
@Component({
  selector: 'yf-cost-info',
  templateUrl: './cost-info.component.html',
  styleUrls: ['./cost-info.component.scss'],
})
export class CostInfoComponent implements OnDestroy {
  subscription: Subscription;
  constructor(
    private sessionService: SessionService
  ) {
    effect(() => {
      this.calculateCosts(this.$session());
    }, { allowSignalWrites: true });
  }

  $session = input<ISession>(null);
  $cost = signal<number>(null);
  $class = input<string>(null);

  calculateCosts(session: ISession) {
    if (!!session?.endedAt) return null;
    if (session?.state === State.CHARGING) {
      this.$cost.set(session.totalCost);
    }
    else if (!!session?.reservedAt || !!session?.occupationTime || !!session?.stoppedAt) {
      this.subscription = timer(0, 30000).pipe(
        filter(() => !!session),
        shareReplay({ bufferSize: 1, refCount: true })
      ).subscribe(c => {
        return this.$cost.set(this.sessionService.calculateCost(session)?.totalCost)
      })
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
