"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfileTypeV2_2 = exports.TokenTypeV2_2 = exports.WhitelistType = exports.TokenTypeV2_1_1 = exports.OCPIAllowedType = void 0;
var OCPIAllowedType;
(function (OCPIAllowedType) {
  OCPIAllowedType["ALLOWED"] = "ALLOWED";
  OCPIAllowedType["BLOCKED"] = "BLOCKED";
  OCPIAllowedType["EXPIRED"] = "EXPIRED";
  OCPIAllowedType["NO_CREDIT"] = "NO_CREDIT";
  OCPIAllowedType["NOT_ALLOWED"] = "NOT_ALLOWED";
})(OCPIAllowedType || (exports.OCPIAllowedType = OCPIAllowedType = {}));
var TokenTypeV2_1_1;
(function (TokenTypeV2_1_1) {
  TokenTypeV2_1_1["OTHER"] = "OTHER";
  TokenTypeV2_1_1["RFID"] = "RFID";
})(TokenTypeV2_1_1 || (exports.TokenTypeV2_1_1 = TokenTypeV2_1_1 = {}));
var WhitelistType;
(function (WhitelistType) {
  WhitelistType["ALWAYS"] = "ALWAYS";
  WhitelistType["ALLOWED"] = "ALLOWED";
  WhitelistType["ALLOWED_OFFLINE"] = "ALLOWED_OFFLINE";
  WhitelistType["NEVER"] = "NEVER";
})(WhitelistType || (exports.WhitelistType = WhitelistType = {}));
var TokenTypeV2_2;
(function (TokenTypeV2_2) {
  TokenTypeV2_2["APP_USER"] = "APP_USER";
  TokenTypeV2_2["AD_HOC_USER"] = "AD_HOC_USER";
  TokenTypeV2_2["RFID"] = "RFID";
  TokenTypeV2_2["OTHER"] = "OTHER";
})(TokenTypeV2_2 || (exports.TokenTypeV2_2 = TokenTypeV2_2 = {}));
var ProfileTypeV2_2;
(function (ProfileTypeV2_2) {
  ProfileTypeV2_2["CHEAP"] = "CHEAP";
  ProfileTypeV2_2["FAST"] = "FAST";
  ProfileTypeV2_2["GREEN"] = "GREEN";
  ProfileTypeV2_2["REGULAR"] = "REGULAR";
})(ProfileTypeV2_2 || (exports.ProfileTypeV2_2 = ProfileTypeV2_2 = {}));
