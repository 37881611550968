<!-- LOGIN  -->
<div
  class="inner-content"
  yf-login-form-template
  *ngIf="type == FormType.Login"
  [header]="{title: 'LOGIN.TITLE' | translate}"
  [forward]="{text: 'LOGIN.PASSWORD_FORGOT' | translate, type: FormType.ForgotPw }"
  (appleLoginEvent)="appleLogin()"
  (googleLoginEvent)="googleLogin()"
  [submitButton]="{label: 'LOGIN.LOGIN_BUTTON' | translate, icon: 'log-in-outline'}"
  [registerButton] = "{label: 'LOGIN.REGISTER' | translate}"
  [form]="this.loginFrm"
  (changeForm)="changeForm($event)"
  (submitEvent)="login()"  
  [type]="FormType.Login">
</div>

<!-- This must be joined with login -->
 <!-- REGISTRAZIONE -->
<div
  class="inner-content"
  yf-login-form-template
  *ngIf="type == FormType.Register"
  [header]="{title: 'LOGIN.CREATE_ACCOUNT' | translate}"
  [back]="{text: 'LOGIN.BACK_TO_LOGIN' | translate, type: FormType.Login }"
  [submitButton]="{label: 'LOGIN.CREATE_ACCOUNT' | translate}"
  [form]="this.registerFrm"
  (appleLoginEvent)="appleLogin()"
  (googleLoginEvent)="googleLogin()"
  (changeForm)="changeForm($event)"
  (submitEvent)="register()"
  [type]="FormType.Register"></div>
<!-- PASSWORD DIMENTICATA -->
<div
  class="inner-content"
  yf-login-form-template
  *ngIf="type == FormType.ForgotPw"
  [header]="{title:'LOGIN.NEW_PASSWORD' | translate}"
  [back]="{text: 'LOGIN.BACK_TO_LOGIN' | translate, type: FormType.Login }"
  [submitButton]="{label: 'LOGIN.NEW_PASSWORD' | translate, icon: 'mail-outline'}"
  [form]="this.forgotPwFrm"
  (changeForm)="changeForm($event)"
  (submitEvent)="forgotPassword()"
  [type]="FormType.ForgotPw"></div>
<!-- CAMBIO PASSWORD  -->
<div
  class="inner-content"
  yf-login-form-template
  *ngIf="type == FormType.ChangePw"
  [header]="{title:'LOGIN.CHANGE_PASSWORD' | translate, subtitle: email}"
  [back]="{text: 'LOGIN.BACK_TO_LOGIN' | translate, type: FormType.Login }"
  [submitButton]="{label: 'BUTTONS.CONFIRM' | translate, icon: 'mail-outline'}"
  [form]="this.newPasswordFrm"
  (changeForm)="changeForm($event)"
  (submitEvent)="changePassword()"
  [type]="FormType.ChangePw">
</div>