"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OCPIVersion = exports.PROFILE = exports.WHITE_LIST = void 0;
exports.WHITE_LIST = {
  /**
   * Token always has to be whitelisted, realtime authorization is not possible/allowed.
   * CPO shall always allow any use of this Token.
   */
  ALWAYS: 'ALWAYS',
  /**
   * It is allowed to whitelist the token, realtime authorization is also allowed.
   * The CPO may choose which version of authorization to use.
   */
  ALLOWED: 'ALLOWED',
  /**
   * In normal situations realtime authorization shall be used.
   * But when the CPO cannot get a response from the eMSP (communication between CPO and eMSP is offline)
   * the CPO shall allow this Token to be used.
   */
  ALLOWD_OFFLINE: 'ALLOWED_OFFLINE',
  /**
   * Whitelisting is forbidden, only realtime authorization is allowed.
   * CPO shall always send a realtime authorization for any use of this Token to the eMSP
   */
  NEVER: 'NEVER'
};
exports.PROFILE = {
  /**
   * Driver wants to use the cheapest charging profile possible.
   */
  CHEAP: 'CHEAP',
  /**
   * Driver wants his EV charged as quickly as possible and is willing to pay a premium for this if needed
   */
  FAST: 'FAST',
  /**
   * Driver wants his EV charged with as much regenerative (green) energy as possible.
   */
  GREEN: 'GREEN',
  /**
   * Driver does not have special preferences
   */
  REGULAR: 'REGULAR'
};
var OCPIVersion;
(function (OCPIVersion) {
  OCPIVersion["V2_1_1"] = "2.1.1";
  OCPIVersion["V2_2"] = "2.2";
})(OCPIVersion || (exports.OCPIVersion = OCPIVersion = {}));
