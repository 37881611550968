import { Injectable, computed } from '@angular/core';
import {
  CU,
  ISession,
  ISessionCommand,
  ISessionResult,
  State,
  OCPITariffDimension,
  TariffUtil,
  OCPICdrDimensionType
} from 'common_library';
import { HttpIoService } from '../communication/http-io.service';
import { UiService } from '../utils/ui.service';
import { ISessionUserHistoryMonth } from 'common_library';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from '../state/app-main-state.service';


const CONTROLLER_ROOT = 'session';

@Injectable({ providedIn: 'root' })
export class SessionService {
  private $userId = computed(() => this.mainStateService.$user()?.id);

  constructor(
    private HIO: HttpIoService,
    private ui: UiService,
    private mainStateService: MainStateService,
    private translate: TranslateService
  ) { }

  async fetchCurrentSessions(): Promise<ISession[]> {
    try {
      let rv: ISession[] = [];
      if (this.$userId()) {
        rv = await this.HIO.get<ISession[]>(`${CONTROLLER_ROOT}/v2/user`);
      }
      console.log('🚀 : SessionService : fetchCurrentSessions', this.$userId(), rv);
      return rv;
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async requestCommand(cmd: ISessionCommand): Promise<ISessionResult> {
    console.log('🚀 : SessionService : cmd', cmd, this.$userId);
    try {
      if (!this.$userId()) throw new Error('Missing UserId');
      const res = await this.HIO.post<ISessionResult, ISessionCommand>(`core/execSessionCommand`, cmd);
      console.log('SessionService : res', res);
      return res;
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async fetchMyLastYearSessions(): Promise<ISession[]> {
    try {
      let rv: ISession[] = [];
      if (this.$userId()) rv = await this.HIO.fetchItems<ISession>(`${CONTROLLER_ROOT}/lastYear`);
      return rv;
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async fetchMyLastMonthHistorySessions(lastMonthLoaded?: string): Promise<ISessionUserHistoryMonth[]> {
    try {
      let rv: ISessionUserHistoryMonth[] = [];
      let endpoint = `${CONTROLLER_ROOT}/month`;
      if (lastMonthLoaded) {
        endpoint = endpoint + `/${lastMonthLoaded}`;
      }
      if (this.$userId()) rv = await this.HIO.fetchItems<ISessionUserHistoryMonth>(endpoint);
      return rv;
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  calcDate(fromDate: Date, toDate?: Date) {
    if (fromDate == null) {
      //SO CHE NON HO QUEL CAMPO
      return '--:--';
    }
    const date_end = toDate ? toDate : new Date();
    const time = CU.timeSpan(fromDate, date_end, true);

    return time;
  }

  timeForElements(session: ISession): any {
    const {
      createdAt,
      startedAt,
      stoppedAt,
      endedAt,
      reservedAt,
      bookingEndAt,
      reservationPrice,
      reservationCost,
      energy,
      energyPrice,
      energyCost,
      occupationPrice,
      occupationCost,
      occupationTime,
      totalCost,
      power
    } = session;
    const costElements = [];

    const getCurrentTime = () => new Date();

    const getTimeSpan = (from, to) => {
      if (!from || !to) {
        return '--:--';
      }
      return CU.timeSpan(new Date(from), new Date(to), true);
    };

    if (!!reservedAt)
      costElements.push({
        type: OCPITariffDimension.TIME,
        from: new Date(reservedAt),
        to: new Date(startedAt || bookingEndAt),
        timeSpan: getTimeSpan(reservedAt, startedAt || getCurrentTime()),
        price: reservationPrice,
        cost: reservationCost || 0,
        class: this.ui.getConnectorStateClass(true, State.RESERVED),
        label: this.translate.instant('APP.INSTALLATION.RESERVATION'),
      });

    if (!!startedAt)
      costElements.push({
        type: OCPITariffDimension.ENERGY,
        from: new Date(startedAt),
        to: new Date(stoppedAt || endedAt || getCurrentTime()),
        timeSpan: getTimeSpan(startedAt, stoppedAt || getCurrentTime()),
        energy: energy || 0,
        price: energyPrice,
        cost: energyCost || 0,
        power: !stoppedAt ? (power || 0) : null,
        class: this.ui.getConnectorStateClass(true, State.CHARGING),
        label: this.translate.instant('APP.INSTALLATION.ENERGY'),
      });

    if (!!occupationTime) {
      let startOccupation = CU.addMinutes(getCurrentTime(), -(occupationTime * 60));
      costElements.push({
        type: OCPITariffDimension.PARKING_TIME,
        from: startOccupation,
        to: getCurrentTime(),
        timeSpan: getTimeSpan(startOccupation, getCurrentTime()),
        price: occupationPrice,
        cost: occupationCost || 0,
        class: this.ui.getConnectorStateClass(true, State.OCCUPIED),
        label: this.translate.instant('APP.INSTALLATION.PARKING'),
      });
    }
    else if (!!stoppedAt)
      costElements.push({
        type: OCPITariffDimension.PARKING_TIME,
        from: new Date(stoppedAt),
        to: getCurrentTime(),
        timeSpan: getTimeSpan(stoppedAt, getCurrentTime()),
        price: occupationPrice,
        cost: occupationCost || 0,
        class: this.ui.getConnectorStateClass(true, State.OCCUPIED),
        label: this.translate.instant('APP.INSTALLATION.PARKING'),
      });

    costElements.push({
      type: 'TOTAL',
      from: new Date(createdAt),
      to: getCurrentTime(),
      timeSpan: getTimeSpan(createdAt, getCurrentTime()),
      cost: totalCost || 0,
      class: { disponibile: true },
      label: this.translate.instant('APP.INSTALLATION.TOTAL'),
    });

    return costElements;
  }

  calculateCost(session: ISession): ISession {
    try {
      const rv = TariffUtil.calculateSessionCosts(session);

      const energy = rv.find((e) => e.type === OCPICdrDimensionType.ENERGY);
      const reservation = rv.find((e) => e.type === OCPICdrDimensionType.RESERVATION_TIME);
      const parkingTime = rv.find((e) => e.type === OCPICdrDimensionType.PARKING_TIME);
      const totalCost = {
        exclVat: CU.roundNumber(energy?.price.exclVat + reservation?.price.exclVat + parkingTime?.price.exclVat),
        inclVat: CU.roundNumber(energy?.price.inclVat + reservation?.price.inclVat + parkingTime?.price.inclVat)
      }
      return {
        ...session,
        energyCost: energy?.price.inclVat,
        reservationCost: reservation?.price.inclVat,
        occupationCost: parkingTime?.price.inclVat,
        totalCost: totalCost?.inclVat
      }
    } catch (ex) { console.error("CALCOLO COSTI", ex) }
    return session;
  }
}
