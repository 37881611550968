@if($connector()){
<ion-card class="connector-card" [ngClass]="$connector().classes" (click)="selectConnector()">
  <ion-card-header class="yf-state" [ngClass]="$connector().classes">
    <ion-text class="title">{{"INSTALLATION.CONNECTOR" | translate}} {{ $connector().name ||
      $connector().cpConnectorId }}</ion-text>
  </ion-card-header>
  <ion-card-content [ngClass]="$connector().classes">
    <div class="icon-row">
      @for (connector of $connector().plugTypes; track $index) {
      <ion-icon class="icon" [src]="'assets/plugTypes/presa_' + connector.toLowerCase() + '.svg'"></ion-icon>
      <span class="connector-type">{{ connector }}</span>
      }
    </div>
    <div class="info-row">
      <span class="info-text">Max <span class="info-value">{{ $connectorPower() | power }}kW</span></span>
      <span class="info-text">€<span class="info-value"> {{ $connectorPrice() | number: '1.2-2'}}/kWh</span></span>
    </div>
    <div class="status-row">
      <span class="status-text">{{$connector().stateLabelLoc }}</span>
      <ion-icon name="ellipse" class="status-icon" [ngClass]="$connector().classes"></ion-icon>
    </div>
  </ion-card-content>
</ion-card>
}