import { DatePipe, registerLocaleData, } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { InjectionToken, LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { IonicModule } from '@ionic/angular';
import { RxState } from '@rx-angular/state';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { PagesModule } from './pages/pages.module';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { ICar, IUser, CU, ITariff, IInfoContent, PlatformType } from 'common_library'
import { _ } from './consts';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { PipesModule } from './pipes/pipes.module';
import { register } from 'swiper/element/bundle';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppMainState, MainState } from './types/state/app-main-state.interface';
import { of } from 'rxjs';
import { v4 } from 'uuid';
import { SwiperDirective } from './directives/swiper-directive';
import { Point } from 'geojson';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

register();


let BACKEND_URL = "http://localhost:3000";

const APP_URL = window.location.href;
const host = window.location.hostname;

if (host === "localhost" || CU.isIpAddress(host)) {
  const mode = APP_URL.split("?")[1];
  // http://localhost:3000/?live-dev oppure http://localhost:8100/?live-dev se usi "ionic serve"
  if (mode && mode.indexOf("live-dev") >= 0) {
    console.log("LIVE DEV MODE (local frontend app over live DEVELOP backend)")
    BACKEND_URL = environment.url;
  } else if (mode && mode.indexOf("live-prod") >= 0) {
    console.log("LIVE PROD MODE (local frontend app over live PRODUCTION backend)")
    BACKEND_URL = environment.prodUrl;
  } else {
    if (Capacitor.getPlatform() !== 'web') {
      BACKEND_URL = environment.url;
    } else {
      BACKEND_URL = `http://${host}:3000`;
    }
  }
} else {
  //Probabile se si cambia l'hostname servirà
  BACKEND_URL = environment.url;
}

console.log("APP_URL", APP_URL);
console.log("BACKEND_URL", BACKEND_URL);

//Localizzazione Angular
registerLocaleData(localeIt, 'it');
export interface IConnectorsTariff {
  tariff: ITariff,
  connectorId: number
}
export interface IFormCdc {
  owner?: string;
  number?: string;
  expiryMonth?: number;
  expiryYear?: number;
  securityCode?: number;
}
// RX-ANGULAR :: GLOBAL STATE #####################################################
export type Nullable<T> = T | null;

export interface GlobalState {
  apiUrl: string,
  mainState: MainState,
  selectedCar: Nullable<ICar>,
  visible: boolean, // indica se l'app è visibile all'utente oppure no (tipo il tab è visibile sia mobile che desktop) -> vedere AppService
  wsComm: boolean,
  httpComm: boolean,
  isDevelop: boolean,
  openByAction: boolean,
  installationByDeeplink: boolean,
  mapLoaded: boolean,
  registrationForm: {
    name?: string,
    surname?: string,
    email?: string,
    password: '',
  },
  formCdc: IFormCdc
}

function globalStateFactory(): RxState<GlobalState> {
  const openByAction = window.location.href.indexOf('action_uid=') > 0;
  const gs = new RxState<GlobalState>();

  gs.set({
    mainState: MainState.INIT,
    isDevelop: !environment.production,
    openByAction
  });
  return gs;
}

function mainStateFactory(): RxState<AppMainState> {
  const mainState = new RxState<AppMainState>();
  let clientId = localStorage.getItem(_.CLIENT_KEY);
  if (!clientId || clientId.startsWith('"')) {
    clientId = v4();
    localStorage.setItem(_.CLIENT_KEY, clientId);
  }

  let clientLastPosition: Point = {
    type: 'Point',
    coordinates: [0, 0]
  };

  const native = Capacitor.isNativePlatform();
  let platformType: string = null;
  if (native) {
    const platform = Capacitor.getPlatform()
    if (platform === 'ios') platformType = PlatformType.iOSNative;
    if (platform === 'android') platformType = PlatformType.AndroidNative;
  }

  mainState.set({
    native,
    platformType,
    clientId,
    clientLastPosition
  });
  return mainState;
}

export const GLOBAL_RX_STATE = new InjectionToken<RxState<GlobalState>>('GLOBAL_RX_STATE');
export const APP_MAIN_RX_STATE = new InjectionToken<RxState<AppMainState>>('APP_MAIN_RX_STATE');

@NgModule({
  declarations: [AppComponent, SwiperDirective],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({ mode: 'ios', scrollAssist: false, scrollPadding: false, innerHTMLTemplatesEnabled: true }),
    ComponentsModule,
    PagesModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    //SocialLoginModule,
    AppRoutingModule,
    ServiceWorkerModule.register('service-worker.js', {
      //enabled: environment.production,
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    PipesModule,
  ],
  providers: [
    [DatePipe],
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: GLOBAL_RX_STATE, useFactory: globalStateFactory },
    { provide: APP_MAIN_RX_STATE, useFactory: mainStateFactory },
    { provide: 'SOCKET_IO_ADDRESS', useValue: BACKEND_URL },
    { provide: 'APP_START_LOCATION', useValue: APP_URL },
    { provide: 'BACKEND_URL', useValue: BACKEND_URL },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    Geolocation,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})


export class AppModule { }
